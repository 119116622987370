class PlayerProgressData {
    constructor(){
        this.playerData = {
            mapLevel:0,
            debugMode:false,
            manualMode:false,
            localMode:{
                enabled:false,
                heroes:[],
                enemies:[],
            },
            partyHeroes:[],
            wonMatch:false,
        }
    }

    getPlayerDataValue(propertyName) {
        return this.playerData[propertyName];
    }

    setPlayerDataValue(propertyName, value) {
        this.playerData[propertyName] = value;
    }

    getPlayerData() {
        return this.playerData;
    }

    addPlayerDataValue(propertyName, value) {
        this.playerData[propertyName] += value;
    }
}

module.exports = new PlayerProgressData();