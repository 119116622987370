export var gameConfig = function () {

    var gameInfo = {

        orientation:'landscape',
        skipLoading:false,
        sceneToTest:'MapScene',
    }

    var playerInfo = {
        playerName:null,
    }

    var mapInfo = {
        milestones:[
            {
                unlocked: true,
                stars: 0,
                position: { x: 0.48327434166462624, y: 0.9630953116041107 },
                type: "mark_regular"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.40272861805385524, y: 0.8987368235346007 },
                type: "mark_regular"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.29941997255308367, y: 0.8682512239227275 },
                type: "mark_regular"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.1750993991538501, y: 0.8580893573854365 },
                type: "mark_regular"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.1575894592384651, y: 0.8038927358532175 },
                type: "mark_regular"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.1908583450776966, y: 0.754777047589644 },
                type: "mark_regular"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.25389412877308265, y: 0.7852626472015172 },
                type: "mark_regular"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.32218289444308423, y: 0.8157482468133904 },
                type: "mark_regular"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.37121072620616224, y: 0.7937308693159265 },
                type: "mark_regular"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.4097325940200092, y: 0.7463088254752349 },
                type: "boss_level"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.4745193717069338, y: 0.7259850924006528 },
                type: "mark_regular"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.5428081373769353, y: 0.712435937017598 },
                type: "mark_regular"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.6163498850215524, y: 0.692112203943016 },
                type: "mark_regular"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.6863896446830924, y: 0.6785630485599612 },
                type: "mark_regular"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.7984532601415566, y: 0.6497710933709699 },
                type: "mark_regular"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.835224133963865, y: 0.6108172716446876 },
                type: "mark_regular"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.7774413322430945, y: 0.595574471838751 },
                type: "mark_regular"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.67, y: 0.62 },
                type: "mark_regular"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.5725750352330898, y: 0.617591849336215 },
                type: "mark_regular"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.4762703656984723, y: 0.6328346491421515 },
                type: "boss_level"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.3992266300707783, y: 0.6243664270277423 },
                type: "mark_regular"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.3186809064600072, y: 0.6108172716446876 },
                type: "mark_regular"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.22062524293385116, y: 0.6074299827989239 },
                type: "mark_regular"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.1786013871369271, y: 0.563395227803996 },
                type: "mark_regular"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.21712325495077414, y: 0.5210541172319499 },
                type: "mark_regular"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.3151789184769302, y: 0.5058113174260134 },
                type: "mark_regular"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.38521867813847027, y: 0.5193604728090682 },
                type: "mark_regular"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.4762703656984723, y: 0.5244414060777136 },
                type: "mark_regular"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.5568160893092434, y: 0.5058113174260134 },
                type: "mark_regular"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.6198518730046294, y: 0.48040665108278574 },
                type: "boss_level"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.6863896446830924, y: 0.44992105147091255 },
                type: "mark_regular"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.7791923262346329, y: 0.4075799408988665 },
                type: "mark_regular"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.8439791039215575, y: 0.3737070524412297 },
                type: "mark_regular"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.8860029597184816, y: 0.3144294976403652 },
                type: "mark_regular"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.894757929676174, y: 0.24668372072509157 },
                type: "mark_regular"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.8982599176592512, y: 0.18401887707846337 },
                type: "mark_regular"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.8667420258115579, y: 0.13151589996912627 },
                type: "mark_regular"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.7914492841754024, y: 0.09764301151148945 },
                type: "mark_regular"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.7319154884630935, y: 0.07901292285978917 },
                type: "mark_regular"
            },
            {
                unlocked: false,
                stars: 0,
                position: { x: 0.6793856687169384, y: 0.05 },
                type: "final_boss_level"
            } 
        ],
        currentMilestone:0,
    }

    var charactersPositions = [{x:-140,y:-150},{x:-140,y:150},{x:-310,y:-325},{x:-310,y:325},{x:-310,y:0}];
    
    function restartMilestones(){
        mapInfo.milestones.forEach((milestone,index)=>{
            if(index == 0)
                milestone.unlocked = true;
            else
                milestone.unlocked = false;
            milestone.stars = 0;
        });
    }

    function getInfo(){
        return gameInfo;
    }

    function setPlayerInfoProperty(property,value){
        playerInfo[property] = value;
    }

    function getPlayerInfoProperty(property){
        return playerInfo[property];
    }

    function getMapProperty(property){
        return mapInfo[property];
    }

    function setMapProperty(property,value){
        mapInfo[property] = value;
    }

    function getHeroesCharacters(){
        return characters.heroes;
    }

    function getEnemiesCharacters(){
        return characters.enemies;
    }

    function getCharacterPositions(){
        return charactersPositions;
    }

	return{
        restartMilestones:restartMilestones,
        getInfo:getInfo,
        getMapProperty:getMapProperty,
        setPlayerInfoProperty:setPlayerInfoProperty,
        getPlayerInfoProperty:getPlayerInfoProperty,
        getHeroesCharacters:getHeroesCharacters,
        getEnemiesCharacters:getEnemiesCharacters,
        setMapProperty:setMapProperty,
        getCharacterPositions:getCharacterPositions,
	}
		
}()