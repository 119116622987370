export default class HealthBar extends Phaser.GameObjects.Container{

    constructor(scene,position,scaleValue,rightLooking = false,initialPoints){
        super(scene, position.x, position.y);
        this.tweens = scene.tweens;
        
        let heroHealthBar = scene.add.image(0,0,'fight_atlas','health_bar').setOrigin(1,0.5);
		this.add(heroHealthBar);

		let heroGreenBar = scene.add.image(-heroHealthBar.width * 0.035,0,'fight_atlas','health_fill').setOrigin(1,0.5);
		this.add(heroGreenBar);
		this.greenBar = heroGreenBar;
        this.originalScale = {};
        this.originalScale.x = rightLooking ? -scaleValue : scaleValue;
        this.originalScale.y = scaleValue;
        this.setScale(this.originalScale.x, this.originalScale.y);

        if (!rightLooking) {
            heroGreenBar.setTexture('fight_atlas','enemy_health_fill'); 
        }

        this.initialPoints = initialPoints;
    }

    setScaleY(value){
        this.originalScale.y = value;
        this.setScale(this.originalScale.x, this.originalScale.y);
    }

    restartBar(){
        this.alpha = 1;
        this.tweens.add({
            targets:this,
            scaleX:{from:0,to:this.originalScale.x},
            scaleY:this.originalScale.y,
            ease:'Back.easeOut',
            duration:500,
        });

        this.tweens.add({
            targets:this.greenBar,
            scaleX:{from:0,to:1},
            duration:500,
        });

        //this.greenBar.setFrame('Green_Bar');
    }

    updateBar(value){
        let scaleValue = value / this.initialPoints;
        scaleValue = scaleValue > 1 ? 1 : scaleValue;
        if (this.updateBarTween) {
            this.updateBarTween.stop();
            this.updateBarTween = null;
        }
		this.updateBarTween = this.tweens.add({
			targets:this.greenBar,
			scaleX:scaleValue,
			duration:500,
		});

		if(value <= (this.initialPoints * 0.3)){
			//this.greenBar.setFrame('Red_Bar');
        }
    }
}