import {gameConfig} from '../libs/gameConfig';
import GameUtils from '../libs/GameUtils.js';
import ScrollingCamera from 'phaser3-scrolling-camera';
import CircleMaskImage from 'phaser3-rex-plugins/plugins/circlemaskimage.js';
import Effects from '../utilities/Effects';

const playerProgressData = require('../utilities/PlayerProgressData.js');
const webSocketConnection = require('../utilities/WebSocketConnection.js');
const sound = require('../libs/Sound.js');
export default class mapScene extends Phaser.Scene{

	constructor(){
		super("MapScene");
		//this._levelConfig = LevelConfig[1];
	}

	preload(){

		this.gameUtils = new GameUtils(this);
		this.gameMode = playerProgressData.getPlayerDataValue('gameMode');
	}

	
	addParticles(){

		this.effects = new Effects(this);
	};

	create(){
		
		this.createMapBackground(2);
		//this.createScrollCamera();
		this.createUI();
		//this.createPositioningTest();
		this.createMapMilestones();
		this.addParticles();
		this.show();

		/* if (!this.scene.isActive("TransitionScene")) {
			this.scene.launch("TransitionScene");
		} else {
			this.scene.get("TransitionScene").events.emit('open_transition', 1000);
		} */
			
		
	}

	createHeroEntry(container,characterData){

		let heroEntry = this.add.container();
		container.add(heroEntry);

		let heroImage = this.add.image(0,0,'characters_atlas',characterData.name);
		heroEntry.add(heroImage);

		let heroNameText = this.add.bitmapText(0,heroImage.displayHeight * 0.5 + 10,'grobold',characterData.name,30,1).setOrigin(0.5);
		heroEntry.add(heroNameText);
	}

	createMapBackground(zonesNumber){

		/* let pivotY = 0;
		for (let i = 0; i < zonesNumber; i++) {
			let background = this.add.image(0,pivotY,'map_atlas','zone_map_' + (i + 1)).setOrigin(0,0);
			let originalWidth = background.displayWidth;
			background.displayWidth = screen.width;
			background.displayHeight = (background.displayWidth * background.displayHeight) / originalWidth;
			pivotY += background.displayHeight;
		} */

		let background = this.add.image(0,0,'map_atlas','zone_map_1').setOrigin(0,0);
		background.displayWidth = screen.width;
		background.displayHeight = screen.height;
		this.background = background;
	}

	createPositioningTest(){

		this.piecesTestContainer = this.add.container(0,0);

		this.background.setInteractive();
		this.background.on('pointerdown',(pointer)=>{

			let x = pointer.x;
			let y = pointer.y;

			let piece = this.add.image(x,y,'map_atlas','mark_regular').setScale(0.4);
			piece.y-= piece.displayHeight * 0.25;
			this.piecesTestContainer.add(piece);
		});

		this.testmarker = this.add.image(75,75,'map_atlas','mark_regular');
		this.testmarker.setInteractive();
		this.testmarker.on('pointerdown',()=>{

			let positionList = [];
			this.piecesTestContainer.list.forEach((piece)=>{
				positionList.push({x:piece.x / screen.width, y:(piece.y - this.background.y) / this.background.displayHeight});
			});
			console.log(positionList);
		});
		console.log(this.testmarker.x + " " + this.testmarker.y);
	}

	createMapMilestones(){
		let milestones = gameConfig.getMapProperty('milestones');
		let milestoneIndex = playerProgressData.getPlayerDataValue('mapLevel');
		this.mapMilestones = this.add.container(0,0);
		this.mapMilestones.buttonList = [];
		milestones.forEach((milestone,index)=>{
			let milestoneImage = this.add.image(milestone.position.x * this.background.displayWidth,
				this.background.y + milestone.position.y * this.background.displayHeight, 'map_atlas',milestone.type).setScale(0.3);
			milestoneImage.milestoneIndex = index;
			if (index == milestoneIndex && this.gameMode == "campaign") {
				milestoneImage.on('pointerdown',()=>{
					this.gameUtils.activateScreenButtons(this.mapMilestones.buttonList, false);
					this.gameUtils.activateScreenButtons(this.bottomBar.buttonList, false);
					gameConfig.setMapProperty('currentMilestone',milestoneImage.milestoneIndex);
					this.gameUtils.scaleButton(milestoneImage,()=>{
						playerProgressData.setPlayerDataValue('isSandbox', false);
						//this.scene.get("TransitionScene").events.emit('start_transition', 1000,()=>{
						this.effects.fadeOut(500,()=>{
							sound.stopSong();
							this.scene.start("FightScene");
						});
					});
					sound.play("pop");
				})
				milestoneImage.setInteractive();
			} else {
				//milestoneImage.setAlpha(0.6);
			}
			this.mapMilestones.add(milestoneImage);
			this.mapMilestones.buttonList.push(milestoneImage);
		})
	}

	createScrollCamera(){
		let cameraOptions = {
			contentBounds: {   // Determines the limits of the area where the camera is looking. (optional)
				x: 0,          // x position of contents from top-left origin (default = cameraOptions.x)
				y: 0,           // y position of contents from top-left origin (default = cameraOptions.y)
				length: this.mapInfo.mapHeight     // Distance measured in pixels along the camera main axis
			},
			horizontal: false   // Does this camera use horizontal scroll (default = false)
		};
		this.scrollCamera = new ScrollingCamera(this, cameraOptions);
	}

	createUI(){
		this.createTopBar();
		this.createBottomBar();
	}

	createTopBar(){
		this.mapTopBar = this.add.container(0,0);
		this.mapTopBar.setScrollFactor(0,0);

		let topBarImage = this.add.image(0,0,'map_atlas','map_top_bar');
		topBarImage.setOrigin(0,0);
		topBarImage.displayWidth = screen.width;
		this.mapTopBar.add(topBarImage);
		this.mapTopBar.topBarImage = topBarImage;

		let addGoldButton = this.add.image(screen.width, topBarImage.displayHeight * 0.69, 'map_atlas', 'buy_gold_button').setInteractive();
		addGoldButton.x -= addGoldButton.displayWidth * 0.75;
		addGoldButton.on('pointerdown',()=>{
			sound.play("pop");
			addGoldButton.disableInteractive();
			this.gameUtils.scaleButton(addGoldButton,()=>{
				addGoldButton.setInteractive();
				console.log("Add gold button pressed");
			});
		});

		this.coinsUI = this.add.container(0,0);
		this.mapTopBar.add(this.coinsUI);

		let coinsBar = this.add.image(0,0,'fight_atlas','currency_box');
		this.coinsUI.add(coinsBar);

		let coinsIcon = this.add.image(-coinsBar.width * 0.4,0,'fight_atlas','gold logo').setScale(0.6);
		this.coinsUI.add(coinsIcon);

		let coinsText = this.add.bitmapText(coinsBar.displayWidth * 0.2, -2, 'fgd_font', '0', 35).setOrigin(0, 0.5);
		this.coinsUI.add(coinsText);
		this.coinsUI.coinsText = coinsText;

		this.coinsUI.setPosition(addGoldButton.x - addGoldButton.displayWidth * 0.5 - coinsBar.displayWidth * 0.55, addGoldButton.y);

		this.diamondsUI = this.add.container(0,0);
		this.mapTopBar.add(this.diamondsUI);

		let diamondsBar = this.add.image(0,0,'fight_atlas','currency_box');
		this.diamondsUI.add(diamondsBar);

		let diamondsIcon = this.add.image(-diamondsBar.width * 0.4,0,'fight_atlas','diamond');
		this.diamondsUI.add(diamondsIcon);

		let diamondsText = this.add.bitmapText(diamondsBar.displayWidth * 0.2, coinsText.y, 'fgd_font', '0', 35).setOrigin(0, 0.5);
		this.diamondsUI.add(diamondsText);
		this.diamondsUI.diamondsText = diamondsText;

		this.diamondsUI.setPosition(this.coinsUI.x - coinsBar.displayWidth * 0.5 - diamondsBar.displayWidth * 0.7, addGoldButton.y);

		this.playerLevel = this.add.container(0,topBarImage.displayHeight * 0.97).setScale(0.9);
		this.mapTopBar.add(this.playerLevel);

		let playerLevelBar = this.add.image(-40, 0, 'map_atlas', 'player_level_box').setOrigin(0, 0.5);
		this.playerLevel.add(playerLevelBar);

		let playerLevelIcon = this.add.image(-playerLevelBar.width * 0.2, 0, 'map_atlas', 'player_level');

		//let playerImage = this.add.rexCircleMaskImage(playerLevelIcon.x, playerLevelIcon.y, 'characters_atlas', 'selection_chinese_Girl', {maskType:0});
		let playerImage = new CircleMaskImage(this, playerLevelIcon.x, playerLevelIcon.y, 'characters_atlas', 'selection_chinese_girl', {maskType:0});
		this.playerLevel.add(playerImage);

		/* let playerIcon = this.add.image(playerLevelIcon.x, playerLevelIcon.y - playerLevelIcon.displayHeight * 0.03, 'characters_atlas', 'bull').setScale(1.05);
		this.playerLevel.add(playerIcon); */

		this.playerLevel.add(playerLevelIcon);

		this.playerLevel.x = playerLevelIcon.displayWidth * 0.8;
		playerLevelBar.y = -playerLevelIcon.displayHeight * 0.3;

		let powerPointsContainer = this.add.container(playerLevelBar.displayWidth * 0.27, playerLevelBar.y + playerLevelBar.displayHeight * 1.8);
		this.playerLevel.add(powerPointsContainer);

		let powerPointsIcon = this.add.image(0, 0, 'map_atlas', 'power_logo');
		powerPointsContainer.add(powerPointsIcon);

		let powerPointsText = this.add.bitmapText(powerPointsIcon.displayWidth * 0.7, 0, 'fgd_stroke', '0', 40).setOrigin(0, 1);
		powerPointsContainer.add(powerPointsText);

		let playerNameText = this.add.bitmapText(playerLevelBar.x + playerLevelBar.displayWidth * 0.93, playerLevelBar.y, 'fgd_font', 'Player Name', 30).setOrigin(1, 0.5);
		this.playerLevel.add(playerNameText);
		this.playerLevel.playerNameText = playerNameText;

		let playerLevelText = this.add.bitmapText(playerLevelIcon.x, playerLevelIcon.y + playerLevelIcon.displayHeight * 0.315, 'fgd_stroke', '15', 37).setOrigin(0.5);
		this.playerLevel.add(playerLevelText);
		this.playerLevel.playerLevelText = playerLevelText;

		console.log(screen.width + ' width');
	}

	createBottomBar(){

		this.bottomBar = this.add.container(0,screen.height);
		this.bottomBar.setScrollFactor(0,0);

		let bottomBarImage = this.add.image(0,0,'map_atlas','map_bottom_bar');
		bottomBarImage.setOrigin(0,1);
		bottomBarImage.displayWidth = screen.width;
		this.bottomBar.add(bottomBarImage);
		this.bottomBar.bottomBarImage = bottomBarImage;

		this.bottomBar.buttonList = [];
		let buttons = ['city','dungeon','campaign','heroes','chat'];

		let buttonWidth = screen.width / buttons.length;
		buttons.forEach((buttonName,index)=>{

			let buttonContainer = this.add.container(buttonWidth * index + buttonWidth * 0.5, -bottomBarImage.displayHeight * 0.355);
			this.bottomBar.add(buttonContainer);
			this.bottomBar.buttonList.push(buttonContainer);

			let buttonImage = this.add.image(0,0,'map_atlas','bottom_buttons');
			//buttonImage.displayWidth = buttonWidth * 0.8;
			if (buttonWidth < buttonImage.width) {
				buttonImage.displayWidth = buttonWidth;
			}
			buttonImage.setOrigin(0.5,1);
			buttonContainer.add(buttonImage);
			buttonContainer.buttonImage = buttonImage;

			let buttonIcon = this.add.image(0, 0, 'map_atlas', buttonName).setOrigin(0.5, 1);
			buttonContainer.add(buttonIcon);

			let buttonText = this.add.bitmapText(0,-buttonImage.height * 0.15,'fgd_stroke',this.gameUtils.capitalizeFirstLetter(buttonName == "dungeon" ? "sandbox" : buttonName),35,1).setOrigin(0.5);
			buttonContainer.add(buttonText);

			if (buttonName == "campaign") {
				buttonImage.setTexture('map_atlas','bottom_buttons_selected');
				this.bottomBar.lastSelectedButton = buttonContainer;
			} else {
				buttonContainer.setAlpha(0.6);
			}

			buttonContainer.setSize(buttonImage.width,buttonImage.height);
			if (buttonName == "dungeon" || buttonName == "campaign") {
				buttonContainer.setInteractive();
			} else {
				buttonIcon.setAlpha(0);
				buttonText.setAlpha(0);
				let lockImage = this.add.image(0, - buttonImage.displayHeight * 0.1,'map_atlas','lock_icon').setOrigin(0.5,1);
				buttonContainer.add(lockImage);
				buttonContainer.lockImage = lockImage;
			}
			buttonContainer.buttonIndex = index;
			buttonContainer.on('pointerdown',()=>{
				sound.play("pop");
				this.gameUtils.activateScreenButtons(this.bottomBar.buttonList, false);
				this.gameUtils.activateScreenButtons(this.mapMilestones.buttonList, false);
				//this.activateBottomBarButton(buttonContainer.buttonIndex);
				buttonContainer.buttonImage.setTexture('map_atlas','bottom_buttons_selected');
				buttonContainer.setAlpha(1);
				if (this.bottomBar.lastSelectedButton != buttonContainer) {
					this.bottomBar.lastSelectedButton.buttonImage.setTexture('map_atlas','bottom_buttons');
					this.bottomBar.lastSelectedButton.setAlpha(0.6);
					this.bottomBar.lastSelectedButton = buttonContainer
				}
				this.gameUtils.scaleButton(buttonContainer,()=>{
					if(buttonName == "dungeon") {
						playerProgressData.setPlayerDataValue('isSandbox', true);
						/* this.scene.get("TransitionScene").events.emit('start_transition', 1000,()=>{
							sound.stopSong();
							this.scene.start("FightScene");
						}); */

						this.effects.fadeOut(500,()=>{
							sound.stopSong();
							this.scene.start("FightScene");
						});
					} else {
						this.gameUtils.activateScreenButtons(this.bottomBar.buttonList, true);
						this.gameUtils.activateScreenButtons(this.mapMilestones.buttonList, true);
					}
				});
			});

		})

		this.setBackgroundSize();
	}

	setBackgroundSize(){
		this.background.y = this.mapTopBar.topBarImage.height;
		this.background.displayHeight = screen.height - this.mapTopBar.topBarImage.height - this.bottomBar.bottomBarImage.displayHeight;

		this.mapInfo = {};
		this.mapInfo.mapWidth = screen.width;
		this.mapInfo.mapHeight = screen.height;
	}

	activateBottomBarButton(buttonIndex){
		this.bottomBar.list.forEach((button,index)=>{
			if(index == buttonIndex)
				button.list[0].setFrame('Btn_orange_inactive');
			else
				button.list[0].setFrame('Btn_orange_active');
		})
	}

	show(){
		this.restartAssets();
		this.animateScene();
	}

	animateScene(){
		//this.addCoins(1000,this.titleScreen);
		sound.setMusicVolume(0.3);
		this.effects.showFade(500,()=>{
			this.animateBottomBar();
		});
		sound.playSong("fantasy_ballad");
	}

	restartAssets(){
		this.bottomBar.buttonList.forEach((button,index)=>{
			button.setScale(0);
		});
	}

	animateBottomBar(){
		sound.play("drag");
		this.bottomBar.buttonList.forEach((button,index)=>{
			this.tweens.add({
				targets:button,
				scale:1,
				duration:500,
				delay:index * 100,
				ease:'Back.easeOut'
			})
		})
	}
}