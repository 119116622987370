import 'phaser';
import SpinePlugin from './libs/SpinePlugin.min.js';
import UIPlugin from 'phaser3-rex-plugins/templates/ui/ui-plugin.js';

import ObservableDataSource from './utilities/ObservableDataSource.js';
import GesturesPlugin from 'phaser3-rex-plugins/plugins/gestures-plugin.js';
import MapScene from './scenes/MapScene.js';
import LoginScene from './scenes/LoginScene.js';
import PreloaderIntro from './scenes/PreloadScene.js';
import ResultsScene from './scenes/ResultsScene.js';
import FightScene from './scenes/FightScene.js';
import Screen from './utilities/Screen.js';

const playerProgressData = require('./utilities/PlayerProgressData.js');
const webSocketConnection = require('./utilities/WebSocketConnection.js');

var game;
const gameHeight = 1920;
const gameWidth = Math.round(gameHeight * (window.innerWidth / window.innerHeight));

window.onload = function(){
    var gameConfig = {
        backgroundColor: "#000000",
        scene:[PreloaderIntro, LoginScene, MapScene, FightScene, ResultsScene],
        type: Phaser.AUTO,
        scale:{
            mode: Phaser.Scale.FIT,
            parent:'game',
            autoCenter: Phaser.Scale.CENTER_BOTH,
            width:gameWidth,
            height:gameHeight,
        },
        autoFocus: true,
        fps: {
            target: 60,
            forceSetTimeOut: true
        },
        plugins: {
            scene: [
                { key: 'SpinePlugin', plugin: window.SpinePlugin, mapping: 'spine' },
                {
                    key: 'rexGestures',
                    plugin: GesturesPlugin,
                    mapping: 'rexGestures'
                },
                {
                    key: 'rexUI',
                    plugin: UIPlugin,
                    mapping: 'rexUI'
                },
            ]
        },
        dom: {
            createContainer: true
        },
        powerPreference: 'high-performance',
        disableContextMenu: false
    }
    
    game = new Phaser.Game(gameConfig);
    screen = new Screen(game);


    function setFireworks(){

        let fireworksUrl = 'wss://staging1.z8f0n2p4.theworldslargest.com:8185/game';
        if (window.location.hostname != "localhost") {
            fireworksUrl = "wss://" + window.location.hostname + ":8185/game";
        }

        console.log('fireworksurl',fireworksUrl);
        webSocketConnection.setObserver(new ObservableDataSource());
        webSocketConnection.openSocket(fireworksUrl);
        //webSocketConnection.openSocket('ws://64.23.194.50:8185/game');
        webSocketConnection.setEventCallback('turn-did-happen',(msg)=> webSocketConnection.observer.receiveData(msg.match),true);
        webSocketConnection.setEventCallback('match-will-start',(data)=> {
            playerProgressData.setPlayerDataValue("matchInfo",data.match);
            webSocketConnection.finishedTask();
        },true);
        webSocketConnection.setEventCallback('waiting-for-action',()=> {
            webSocketConnection.sendMessage(JSON.stringify({ 'type': 'action' }));
            webSocketConnection.finishedTask();
        },true);
        webSocketConnection.setEventCallback('sandbox-select-heroes-and-enemies',(data)=> {
            playerProgressData.setPlayerDataValue("sandboxData",data);
            webSocketConnection.finishedTask();
        });
        playerProgressData.setPlayerDataValue("gameMode","sandbox");
    }

    setFireworks();
    window.focus();
}