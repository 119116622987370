import SpineAnimation from './SpineAnimation.js';
import Debuffs from './Debuffs.js';
import GameUtils from '../libs/GameUtils.js';
export default class Fighter extends Phaser.GameObjects.Container{
    constructor(scene, position, unitData, characterData, healthBar, attackBar, rightLooking = false, attackController, heroCard, animation = null){
        
        super(scene, position.x, position.y);
        this.heroCard = heroCard;
        this.attackController = attackController;
        this.tweens = scene.tweens;
        this.time = scene.time;
        this.originalPosition = position;
        this.newCharacter = characterData.newCharacter ? true : false;
        if (this.newCharacter) {
            this.attacks = characterData.attacks;
        }
        this.gameUtils = new GameUtils(scene);

        let characterScale = characterData.scale;
        //this.characterAnimation = scene.add.spine(0, 0, characterData.fileName).setScale(characterScale);
        if (animation != null) {
            this.characterAnimation = animation;
            animation.setAlpha(1);
            animation.setPosition(0, 0);
            animation.setScale(characterScale);
        } else {
            this.characterAnimation = new SpineAnimation(scene, 0, 0, characterData.fileName).setScale(characterScale);
        }
        if (characterData.skin) {
            this.characterAnimation.setSkin(characterData.skin);
        }
        if (characterData.rightFacing) {
            this.characterAnimation.scaleX = rightLooking ? characterScale : -characterScale;
        } else {
            this.characterAnimation.scaleX = rightLooking ? -characterScale : characterScale;
        }
        
        this.rightLooking = rightLooking;
        this.add(this.characterAnimation);

        this.healthBar = healthBar;
        healthBar.alpha = 0;
        this.add(this.healthBar);

        this.attackBar = attackBar;
        attackBar.alpha = 0;
        this.add(this.attackBar);

        this.statValues = {};
        if(unitData) {
            this.fighterName = unitData.class;

            this.statValues.unitName = unitData.type;
            this.statValues.attackPoints = unitData.attack_bar;
            this.statValues.attackPower = unitData.attack_power;
            this.statValues.speed = unitData.speed;
            this.statValues.defensePoints = unitData.defense_points;

            this.abilities = unitData.abilities;
        }
        
        this.characterName = characterData.name;
        this.animationNames = characterData.animationNames;
        this.element = characterData.element;
        this.healthPoints = healthBar.initialPoints;

        const debuffInitialX = -20
        this.debuffs = new Debuffs(scene, {x: rightLooking ? debuffInitialX : -debuffInitialX, y:-characterData.height - 35}, 5, 
            this.gameUtils.getImageWidth('stun', 'fight_atlas'), rightLooking, 0.4);
        this.add(this.debuffs);

        this.attackDuration = 600;
        this.isAlive = true;
        scene.events.on("turn_debug_ui", this.turnDebugUIVisible, this);
        scene.events.on("on_victory", this.onVictory, this);
        scene.events.on("game_paused", this.pauseGame, this);
        scene.events.on("change_speed", this.changeSpeed, this);    

        if (characterData.effects != null) {
            scene.effects.createEffectsAnimation(characterData.fileName, characterData.effects);
        }
        this.createDebugUI(scene);
        this.playAnimation("Idle");

        //this.postFX.remove(this.bloomEffect);
    }

    pauseGame(paused, lastSpeed){
        if (paused) {
            this.characterAnimation.timeScale = 0;
        } else {
            this.characterAnimation.timeScale = lastSpeed;
        }
    }

    changeSpeed(speed){
        speed = speed == 1 ? 1 : 1.9;
        this.characterAnimation.state.timeScale = speed;
        //console.log(this.characterAnimation.state.timeScale + " current Speed " + this.characterName);
    }

    onVictory(){
        if (this.isAlive) {
            this.playAnimation("Attack", "Idle");
        }
        this.removeEvents();
    }

    removeEvents() {
        this.scene.events.off("turn_debug_ui", this.turnDebugUIVisible, this);
        this.scene.events.off("on_victory", this.onVictory, this);
        this.scene.events.off("game_paused", this.pauseGame, this);
        this.scene.events.off("change_speed", this.changeSpeed, this);
    }

    createDebugUI(scene){

        this.debugUI = scene.add.container(0,-25);
        this.turnDebugUIVisible(scene.debugMode);
        this.add(this.debugUI);
        
        let debugText = scene.add.bitmapText(0, 0, 'fgd_stroke', "", 27).setOrigin(0.5,1);
        this.debugUI.debugText = debugText;
        this.debugUI.add(debugText);

        if(!this.scene.localMode) {
            this.setDebugData();
        }
    }

    setDebugData(unitData){

        const healthPoints = this.gameUtils.toDecimals(this.healthPoints,1);
        if(unitData && unitData.available) {
            this.statValues.attackPoints = this.gameUtils.toDecimals(unitData.attack_bar,1);
            this.statValues.speed = unitData.speed;
            this.statValues.attackPower = unitData.attack_power;
            this.statValues.defensePoints = unitData.defense_points;
        }

        let name = this.statValues.unitName;
        let indexToSplit = 10;
        if(name.length > indexToSplit) {
            name = name.slice(0, indexToSplit) + "\n" + name.slice(indexToSplit);
        }
        this.debugUI.debugText.setText(name + "\n" + healthPoints + " - HP" + "\n" + this.statValues.attackPoints + " - APTS" + "\n" + 
            this.statValues.attackPower + " - APWR" + "\n" + this.statValues.speed + " - SPD" + "\n" + this.statValues.defensePoints + " - DEF");
    }

    turnDebugUIVisible(visible){
        visible ? this.debugUI.alpha = 1 : this.debugUI.alpha = 0;
    }

    getAnimationKey(animationKey){
        if (!this.animationNames || !this.animationNames[animationKey]) {
            return animationKey;
        } else {
            return this.animationNames[animationKey];
        }
    }

    animationExists(animationKey){
        return !this.newCharacter || this.animationNames[animationKey] != null
    }

    playAnimation(animationKey, nextAction = null, loop = true){
        animationKey = this.getAnimationKey(animationKey);
        if(nextAction) {
            if (typeof nextAction === 'function') {
                this.characterAnimation.playAnimation(animationKey, false, nextAction);
            } else {
                nextAction = this.getAnimationKey(nextAction);
                this.characterAnimation.playAnimationAfter(animationKey, nextAction, true);
            }
        } else {
            this.characterAnimation.playAnimation(animationKey, loop);
        }
    }

    moveToStart(duration){
        this.moveTo(this.originalPosition.x,duration,this.startCharacter.bind(this));
    }

    moveTo(positionX, duration, callback){
        this.playAnimation("Walk");
        this.tweens.add({
            targets:this,
            x:positionX,
            duration:duration,
            onComplete:()=>{
                callback();
            }
        });
    }

    startCharacter(){
        this.playAnimation("Idle");
        this.healthBar.restartBar();
        this.attackBar.restartBar();
    }

    attack(target, targetHealth, callback, ability){
        this.attackController.attack(this, target, targetHealth, callback, ability);
    }

    defeated(){
        this.isAlive = false;
        if (this.animationExists("Death")) {
            this.playAnimation("Death", null, false);
            this.tweens.add({
                targets:this,
                alpha:{from:0, to:1},
                yoyo:true,
                delay: 1500,
                repeat:8,
                duration:50,
            });
        } else {
            this.characterAnimation.clearTracks();
            let duration = 300;
            this.tweens.add({
                targets:this,
                alpha:0,
                duration:duration,
                scale:{from:1, to:0.95},
                x:this.rightLooking ? this.x - 15 : this.x + 15,
            });
        }        
    }

    getDamage(getIdle = false){
        if (this.animationExists("GetHit")) {
            getIdle ? this.playAnimation("GetHit", "Idle") : this.playAnimation("GetHit", null, false);
        } else {
            let offsetX = this.rightLooking ? -screen.width * 0.05 : screen.width * 0.03;
            if (this.damageTween) {
                this.damageTween.stop();
            }
            this.damageTween = this.tweens.add({
                targets:this,
                x:{from:this.originalPosition.x, to:this.x + offsetX},
                scaleX:{from:1, to:0.9},
                scaleY:{from:1, to:0.9},
                duration:100,
                yoyo:true,
                repeat:0,
                onComplete:()=>{
                    this.playAnimation("Idle");
                    this.damageTween = null;
                }
            });

            this.addBloomEffect();
        }
    }

    addBloomEffect(){   
        if (this.bloomEffect) {
            this.postFX.remove(this.bloomEffect);
        }
        if (this.effectTween) {
            this.effectTween.stop();
        }
        this.bloomEffect = this.postFX.addBloom(0xffffff, 1, 1, 1, 1);
        this.effectTween = this.tweens.add({
            targets:this.bloomEffect,
            strength:5,
            //blurStrength:2,
            duration:100,
            yoyo:true,
            onComplete:()=>{
                this.postFX.remove(this.bloomEffect);
            }
        });
    }

    updateHealth(remainingHealth){   
        if (typeof remainHealth !== 'Number') {
            remainingHealth = Number(remainingHealth);
        }
        if (typeof this.healthPoints !== 'Number') {
            this.healthPoints = Number(this.healthPoints);
        }

        this.damagePoints = 0;
        let isDamaging = this.healthPoints > remainingHealth;
        if (isDamaging) {
            this.damagePoints = this.healthPoints - remainingHealth;
        }  
        remainingHealth = remainingHealth < 0 ? 0 : remainingHealth;   
        this.healthBar.updateBar(remainingHealth);
        if (isDamaging) {
            this.getDamage(remainingHealth > 0);
        }
        this.healthPoints = remainingHealth;
        this.setDebugData();
        if (this.heroCard) {
            this.heroCard.updateHealth(remainingHealth);
        }
    }    

    updateAttack(value){
        this.attackBar.updateBar(value);
        if (this.heroCard) {
            this.heroCard.updateAttack(value);
        }
    }
}