
import GameUtils from '../libs/GameUtils.js';
import Effects from '../utilities/Effects.js';

const sound = require('../libs/Sound.js');
const playerProgressData = require('../utilities/PlayerProgressData.js');

export default class resultsScene extends Phaser.Scene{

	constructor(){
		super("ResultsScene");
	}

	preload(){

		this.gameUtils = new GameUtils(this);
		sound.setContext(this);

		this.wonMatch = playerProgressData.getPlayerDataValue("wonMatch");
	}

	create(){
		
		this.createBackground();
		this.effects = new Effects(this);
		this.show();
	}

	createBackground(){

		this.resultsScreen = this.add.container(0, 0);
		this.resultsScreen.buttonList = [];
		this.resultsScreen.appearAssets = [];

		if (this.wonMatch) {
			this.createWinScreen();
		} else {
			this.createLoseScreen();
		}
	}

	show(){
		this.hideAssets();
		this.effects.showFade(500,this.animateAssets.bind(this));
	}

	hideAssets(){
		this.resultsScreen.appearAssets.forEach(asset => {
			asset.alpha = 0;
		});
	}

	animateAssets(){
		this.wonMatch ? sound.play("winwin") : sound.play("gameLose");
		sound.playWithDelay(this.wonMatch ? "victory" : "you_lose", 1100);
		this.resultsScreen.appearAssets.forEach((asset, index) => {
			this.tweens.add({
				targets: asset,
				alpha: 1,
				x:{from:asset.x - Phaser.Math.Between(1, 10) * 10, to:asset.x},
				duration: 500,
				delay: index * 350
			});
		});
	}

	createWinScreen(){

		let background = this.add.image(0, 0, 'results_atlas', 'win_background').setOrigin(0, 0);
		background.displayWidth = screen.width;
		background.displayHeight = screen.height;
		this.resultsScreen.add(background);

		let winTop = this.add.image(0, 0, 'results_atlas', 'win_background_box_top').setOrigin(0, 0);
		winTop.displayWidth = screen.width;
		this.resultsScreen.add(winTop);

		let winBottom = this.add.image(0, screen.height, 'results_atlas', 'win_background_box_bottom').setOrigin(0, 1);
		winBottom.displayWidth = screen.width;
		this.resultsScreen.add(winBottom);

		let winText = this.add.image(screen.centerX, 0, 'results_atlas', 'victory').setOrigin(0.5, 0);
		this.resultsScreen.add(winText);
		this.resultsScreen.appearAssets.push(winText);

		let knight = this.add.image(screen.centerX, screen.height * 0.45, 'results_atlas', 'knight').setOrigin(0.5, 0.5);
		this.resultsScreen.add(knight);
		this.resultsScreen.appearAssets.push(knight);

		let winMiddleBand = this.add.image(screen.centerX, screen.height * 0.6, 'results_atlas', 'win_middle_band').setOrigin(0.5, 0.5);
		winMiddleBand.displayWidth = screen.width;
		this.resultsScreen.add(winMiddleBand);

		this.createWinRewards();

		let continueButton = this.add.container(screen.centerX, screen.height * 0.9);
		this.resultsScreen.add(continueButton);
		this.resultsScreen.appearAssets.push(continueButton);

		let continueButtonImage = this.add.image(0, 0, 'results_atlas', 'win_tap_to_continue');
		continueButton.add(continueButtonImage);

		let continueButtonText = this.add.bitmapText(0, -continueButton.displayHeight * 0.05, 'fgd_font', 'Tap to continue', 50).setOrigin(0.5);
		continueButton.add(continueButtonText);
		
		continueButton.setSize(continueButtonImage.displayWidth, continueButtonImage.displayHeight);
		continueButton.setInteractive();
		continueButton.on('pointerdown', () => {
			sound.play("click");
			continueButton.disableInteractive();
			this.gameUtils.scaleButton(continueButton, () => {
				//this.scene.get("TransitionScene").events.emit("start_transition",1000,()=>{
				this.effects.fadeOut(500, () => {
					this.scene.start("MapScene");
				});
			});
		});

	}

	createWinRewards(){
		let rewardsTitleContainer = this.add.container(screen.centerX, screen.height * 0.62);
		this.resultsScreen.add(rewardsTitleContainer);
		this.resultsScreen.appearAssets.push(rewardsTitleContainer);

		let rewardsBackground = this.add.image(0, 0, 'results_atlas', 'rewards_win_box');
		rewardsBackground.displayWidth = screen.width * 0.9;
		rewardsTitleContainer.add(rewardsBackground);

		let rewardsText = this.add.bitmapText(-rewardsBackground.displayWidth * 0.45, -rewardsBackground.displayHeight * 0.05, 'fgd_font', 'Rewards', 35).setOrigin(0,0.5);
		rewardsTitleContainer.add(rewardsText);

		let rewardsFrame = this.add.image(screen.centerX, screen.height * 0.72, 'results_atlas','win_tap_to_continue');
		rewardsFrame.displayWidth = screen.width * 0.9;
		rewardsFrame.displayHeight*= 2.3;
		this.resultsScreen.add(rewardsFrame);

		let rewardsContainer = this.add.container(rewardsFrame.x, rewardsFrame.y);
		this.resultsScreen.add(rewardsContainer);
		this.resultsScreen.appearAssets.push(rewardsContainer);

		let numberOfRewards = 4;
		let totalWidth = 0;
		let rewardNames = ['gold','gold','hero_xp','xp'];
		for (let i = 0; i < numberOfRewards; i++) {
			let reward = this.add.container(0, 0);
			let rewardImage = this.add.image(0, 0, 'results_atlas', rewardNames[i] + '_box');
			reward.add(rewardImage);
			let rewardLogo = this.add.image(0, 0, 'results_atlas', rewardNames[i] + '_logo');
			reward.add(rewardLogo);
			reward.x =  i * rewardImage.displayWidth * 0.85;
			rewardsContainer.add(reward);
			if (i < numberOfRewards - 1) {
				totalWidth += rewardImage.displayWidth * 0.85;
			}
		}

		rewardsContainer.list.forEach((reward, index) => {
			reward.x -= totalWidth * 0.5;
		});

	}

	createLoseScreen(){

		let background = this.add.image(0, 0, 'results_atlas', 'defeat_background').setOrigin(0, 0);
		background.displayWidth = screen.width;
		background.displayHeight = screen.height;
		this.resultsScreen.add(background);

		let defeatTop = this.add.image(0, 0, 'results_atlas', 'defeat_top').setOrigin(0, 0);
		defeatTop.displayWidth = screen.width;
		this.resultsScreen.add(defeatTop);

		let defeatBottom = this.add.image(0, screen.height, 'results_atlas', 'defeat_bottom_line').setOrigin(0, 1);
		defeatBottom.displayWidth = screen.width;
		this.resultsScreen.add(defeatBottom);

		let winText = this.add.image(screen.centerX, 0, 'results_atlas', 'defeat').setOrigin(0.5, 0);
		this.resultsScreen.add(winText);
		this.resultsScreen.appearAssets.push(winText);

		let skeleton = this.add.image(screen.centerX, screen.height * 0.4, 'results_atlas', 'skeleton').setOrigin(0.5, 0.5);
		this.resultsScreen.add(skeleton);
		this.resultsScreen.appearAssets.push(skeleton);

		let loseMiddleBand = this.add.image(screen.centerX, screen.height * 0.57, 'results_atlas', 'defeat_middle_band');
		let scale = screen.width * 0.9 / loseMiddleBand.width;
		loseMiddleBand.displayWidth = screen.width * 0.9;
		loseMiddleBand.displayHeight *= scale;
		this.resultsScreen.add(loseMiddleBand);

		this.createLoseButtons();

		let continueButton = this.add.container(screen.centerX, screen.height * 0.89);
		this.resultsScreen.add(continueButton);
		this.resultsScreen.appearAssets.push(continueButton);

		let continueButtonImage = this.add.image(0, 0, 'results_atlas', 'defeat_tap_to_continue_back');
		continueButton.add(continueButtonImage);

		let continueButtonText = this.add.bitmapText(0, -continueButtonImage.displayHeight * 0.05, 'fgd_font', 'Tap to continue', 50).setOrigin(0.5);
		continueButton.add(continueButtonText);
		
		continueButton.setSize(continueButtonImage.displayWidth, continueButtonImage.displayHeight);
		continueButton.setInteractive();
		continueButton.on('pointerdown', () => {
			sound.play("click");
			continueButton.disableInteractive();
			this.gameUtils.scaleButton(continueButton, () => {
				//this.scene.get("TransitionScene").events.emit("start_transition",1000,()=>{
				this.effects.fadeOut(500, () => {
					this.scene.start("MapScene");
				});
			});
		});

	}

	createLoseButtons(){
		let buttonsInfo = [
			{text:"Adjust your Formation", image:"adjust_logo", tag:"adjust"},
			{text:"Level Up your Heroes", image:"level up", tag:"level_up"},
			{text:"Upgrade your Equipment", image:"upgrade_logo", tag:"upgrade"},
		]

		let startPositionY = screen.height * 0.63;
		let offsetButtons = 160;
		buttonsInfo.forEach((buttonInfo, index) => {
			let button = this.add.container(screen.centerX, startPositionY + index * offsetButtons);
			this.resultsScreen.add(button);
			this.resultsScreen.appearAssets.push(button);

			let buttonImage = this.add.image(0, 0, 'results_atlas', 'defeat_box');
			buttonImage.displayWidth = screen.width * 0.85;
			buttonImage.displayHeight*= 0.9;
			button.add(buttonImage);

			let buttonIcon = this.add.image(-buttonImage.displayWidth * 0.35, 0, 'results_atlas', buttonInfo.image);
			button.add(buttonIcon);

			let buttonText = this.add.bitmapText(0, 0, 'fgd_font', buttonInfo.text, 36).setOrigin(0.5);
			button.add(buttonText);

			button.setSize(buttonImage.displayWidth, buttonImage.displayHeight);
			button.setInteractive();
			button.on('pointerdown', () => {
				sound.play("click");
				button.disableInteractive();
				this.gameUtils.scaleButton(button, () => {
					button.setInteractive();
				});
			});
		});
	}
}