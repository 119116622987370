export default class Pause{
    constructor(scene){
        this.scene = scene;
        this.gamePaused = false;

        this.fade = scene.add.rectangle(screen.centerX, screen.centerY, screen.width, screen.height, 0x000000);
        this.fade.setAlpha(0);

        this.pauseText = scene.add.bitmapText(screen.centerX, screen.height * 0.37, 'fgd_stroke', 'Paused', 80).setOrigin(0.5);
        this.pauseText.setAlpha(0);
    }

    pauseGame(){

        this.gamePaused = !this.gamePaused;
        if (this.gamePaused) {
            this.scene.tweens.pauseAll();
            this.showPauseScreen();
            this.lastSpeed = this.scene.time.timeScale;
        } else {
            this.scene.tweens.resumeAll();
            this.hidePauseScreen();
        }

        this.scene.events.emit('game_paused', this.gamePaused, this.lastSpeed);
    }

    isGamePaused(){
        return this.gamePaused;
    }

    showPauseScreen(){

        this.pauseText.setScale(1);
        this.pauseText.setAlpha(1);
        this.fade.setAlpha(0.6)
    }

    hidePauseScreen(){
        this.scene.tweens.add({
            targets: this.fade,
            alpha: 0,
            duration: 300,
            ease: 'Power2',
        });

        var scaleX = this.pauseText.scaleX
        var scaleY = this.pauseText.scaleY

        this.scene.tweens.add({
            targets:this.pauseText,
            scaleX:scaleX * 0.5,
            scaleY:scaleY * 1.8,
            duration:150,
            onComplete:()=>{
                this.scene.tweens.add({
                    targets:this.pauseText,
                    scaleX:scaleX * 2,
                    scaleY:0,
                    duration:100,
                })
            },
        })
    }
}