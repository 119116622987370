import SpineAnimation from './SpineAnimation.js';
const sound = require('../libs/Sound.js');
export default class AttackController{

    constructor(scene){
        this.tweens = scene.tweens;
        this.events = scene.events;
        this.effects = scene.effects;
        this.time = scene.time;
        this.scene = scene;
    }

    attack(character, target, targetHealth, callback, ability){

        let attackData = character.attacks.find(attack => ability.includes(attack.id));
        if (attackData == undefined) {
			console.warn("Attack not found, using default attack");
            attackData = character.attacks[0];
        }
        character.eventIndex = 0;
        character.effectStarted = false;
		if (character.effects == null) {
			character.effects = [];
		}
        sound.play(attackData.sound);
        this.attackEnemy(character, target, attackData.animationName, attackData.aimBoneName, attackData.startEvent, attackData.hitEvents, 
            attackData.hitChest, attackData.checkInteger, attackData.effectProperties, character.element, ()=>{
                target.updateHealth(targetHealth);
                this.events.emit("unit_damaged", target);
                this.time.delayedCall(600, ()=>{
                    callback();
                });
            });
    }

    attackEnemy(character, enemy, attackAnimation, aimBoneNames, startEventName, hitEventNames, 
        attackChest = false, checkInteger = false, effectProperties = null, element = "star", callback){
		const characterAnimation = character.characterAnimation;
		if (aimBoneNames != null) {
			if (typeof aimBoneNames !== 'string') {
				aimBoneNames.forEach((aimBoneName,index)=>{
					characterAnimation.positionBone(enemy, aimBoneName, {x:0,y:attackChest ? -150 : 0});
				});
			} else {
				characterAnimation.positionBone(enemy, aimBoneNames, {x:0,y:attackChest ? -150 : 0});
			}
		}

		let listenEvent = (event, onComplete) => {
			if (!character.effectStarted && startEventName != null && startEventName == event.data.name) {
				character.effectStarted = true;
				if (effectProperties != null) {
					this.addEffect(character, enemy, effectProperties);
				}
			}
			if (hitEventNames.includes(event.data.name) && (!checkInteger || (checkInteger && event.intValue == 1))) {
				enemy.getDamage();
				this.effects.showParticles('vfx_atlas', element + "_particle", enemy, 12, {x:0, y:-150});
				character.eventIndex++;
				if (character.eventIndex >= hitEventNames.length || typeof hitEventNames === 'string') {
					character.eventIndex = 0;
					onComplete();
                    callback();
				} else {
                    sound.play('punch');
                }
			}
		}

		const trackEntry = characterAnimation.playAnimation(attackAnimation, false, ()=>{
			character.playAnimation("Idle");
		})
		
		characterAnimation.setAnimationEvents(trackEntry, listenEvent);
	}

    getEffectFromCharacter(character){
		return character.effects.find((effectObject) => effectObject.alpha == 0);;
	}

    addEffect(character, enemy, effectProperties){

		let effect = this.getEffectFromCharacter(character);
		if (effect == null) {
			effect = new SpineAnimation(this.scene, enemy.x, enemy.y, effectProperties.name).setScale(0.4);
			character.effects.push(effect);
		} else {
			effect.alpha = 1;
			effect.setPosition(enemy.x, enemy.y);
		}
		
		if (effectProperties.animations.start != null) {
			effect.playAnimation(effectProperties.animations.start, false, () =>{
				if (effectProperties.animations.loop != null) {
					effect.playAnimation(effectProperties.animations.loop,true);
				} else {
					effect.alpha = 0;
				}

				if (effectProperties.time != null) {
					this.time.delayedCall(effectProperties.time,()=>{
						if (effectProperties.animations.end != null) {
							effect.playAnimation(effectProperties.animations.end,false, ()=>{
								effect.alpha = 0;
							});
						}
					});
				}
			});
		}
	}

}