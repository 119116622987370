import {gameConfig} from '../libs/gameConfig.js';
import GameUtils from '../libs/GameUtils.js';
import FightBackground from '../utilities/FightBackground.js';
import AttackController from '../utilities/AttackController.js';
import Fighter from '../utilities/Fighter.js';
import HeroCard from '../utilities/HeroCard.js';	
import HealthBar from '../utilities/HealthBar.js';
import AttackBar from '../utilities/AttackBar.js';
import Counter from '../utilities/Counter.js';
import Effects from '../utilities/Effects.js';
import Pause from '../utilities/Pause.js';
import SpineAnimation from '../utilities/SpineAnimation.js';

const playerProgressData = require('../utilities/PlayerProgressData.js');
const webSocketConnection = require('../utilities/WebSocketConnection.js');
const sound = require('../libs/Sound.js');
export default class FightScene extends Phaser.Scene{

	constructor(){
		super("FightScene");
	}

	preload(){

		this.gameUtils = new GameUtils(this);
		sound.setContext(this); 
		this.matchReady = false;
		this.isAttacking = false;
		this.matchEnded = false;
		this.debugMode = false;
		this.manualMode = false;
		this.readyToSendAction = false;
		this.isSandbox = playerProgressData.getPlayerDataValue('isSandbox');

		webSocketConnection.observer.subscribe(this.updateClientData.bind(this));
		webSocketConnection.setEventCallback('attack-did-happen',this.fighterAttack.bind(this));
		webSocketConnection.setEventCallback('unit-dies',(message) => this.unitDefeated(message.unit_class));
		webSocketConnection.setEventCallback('end-game',(message) => this.endGame(message.who_wins));
		//webSocketConnection.setEventCallback('waiting-for-action',() => this.setUI(message));

		this.setDebuffCallbacks();
		
		this.events.on('unit_damaged',this.onUnitDamaged,this);
	}

	setDebuffCallbacks(){
		const debuffList = ['attack-power-boost-has-been-casted', /* 'critical-hit-chance-boost-has-been-casted', */ 'defense-break-has-been-casted',
			/* 'gust-has-been-casted', */ 'bleed-has-been-casted', 'burn-has-been-casted', 'stun-has-been-casted'];
		debuffList.forEach((debuff) => {
			webSocketConnection.setEventCallback(debuff, this.applyDebuff.bind(this));
		});

		const updateDebuffList = ['buff-has-been-applied'];
	}

	applyDebuff(message){

		let debuffType = message.type.split('-has-been-casted')[0];
		let target = this.findCharacterByName(message.target);
		if (target) {
			target.debuffs.updateIcon(debuffType, message.duration);
		} else {
			console.error('debuff target not found', message.target);
		}
		webSocketConnection.finishedTask();
	}

	updateClientData(data){
		
		data.opponent_units.forEach((unit) => {
			let enemy = this.findCharacterByName(unit.class);
			if (enemy) {
				if(this.debugMode) {
					enemy.setDebugData(unit);
				}
				enemy.updateAttack(unit.attack_bar);
			}
		});

		data.player_units.forEach((unit) => {
			let hero = this.findCharacterByName(unit.class);
			if (hero) {
				if(this.debugMode) {
					hero.setDebugData(unit);
				}
				hero.updateAttack(unit.attack_bar);
			}
		});
		
		if(!this.isAttacking && !this.matchEnded) {
			this.time.delayedCall(200, ()=>{
				this.finishedTask();
			});
		}	
	}

	setUI(matchData){
		//this.levelText.setText("Level: " + matchData.level);
	}

	
	addParticles(){
		this.counterText = new Counter(this,{x:screen.centerX,y:screen.centerY * 0.8},'fgd_stroke',150,sound);

		this.effects = new Effects(this);
	};

	onUnitDamaged(unit){
		this.effects.damageTargetEffect(unit);
		sound.play(Phaser.Math.Between(0, 5) > 3 ? "explode" : "punch");
	}

	endGame(winner){

		this.changeGameSpeed(1);
		this.showGameButtons(false,true);
		let wonMatch = winner == 'Player';
		playerProgressData.setPlayerDataValue('wonMatch', wonMatch);

		this.time.delayedCall(500,()=>{
			sound.fadeOutSong(750);
		});

		let winDelay = 1000;
		this.matchEnded = true;
		this.time.delayedCall(winDelay,()=>{

			if (wonMatch) {
				playerProgressData.addPlayerDataValue('mapLevel',1);
			}
			this.events.emit('on_victory');
			this.tweens.add({
				targets:this.blackFade,
				alpha:0.7,
				duration:300,
			});

			this.time.delayedCall(2000,()=>{
				this.effects.fadeOut(500, this.goToNextScene.bind(this));
				this.finishedTask();
			});
		});
	}

	goToNextScene(){
		this.removeListeners();
		this.scene.start("ResultsScene");
	}

	removeListeners(){
		webSocketConnection.removeEventCallbacks();
		webSocketConnection.observer.unsubscribe(this.updateClientData.bind(this));
		this.events.off('unit_damaged',this.onUnitDamaged,this);
	}

	unitDefeated(name){

		let unit = this.findCharacterByName(name);
		sound.play("zombieUp");
		if (!unit) {
			console.error('defeated unit not found', name);
			this.finishedTask();
			return;
		}
		unit.defeated();
		if (this.manualMode)
			webSocketConnection.finishedTask();
		this.finishedTask();
	}

	finishedTask(){
		if (this.manualMode) {
			this.readyToSendAction = true;
			this.screenButton.setInteractive();
			this.screenButton.alpha = 0.01;
		} else {
			webSocketConnection.finishedTask();
		}
	}

	create(){
		
		//sound.decode(assetsManager.getGameAssets().assets.soundsList,this)

		this.createBackground();
		this.createBlackFade();
		this.createHeroes();
		this.createUI();
		this.createSandboxUI();
		this.addParticles();
		this.show();
	}

	startMatch(){
		console.log('start match');
		let matchData = playerProgressData.getPlayerDataValue('matchInfo');
		this.setHeroes(matchData);
		this.setUI(matchData);
	}

	showGameButtons(active, immediate){
	
		if (immediate) {
			this.gameButtons.forEach((button) => {
				button.setAlpha(active ? 1 : 0);
			});
		} else {
			let appearDuration = 300;
			this.gameButtons.forEach((button) => {
				if (active) {
					button.setAlpha(1);
					this.tweens.add({
						targets: button,
						scaleX: { from: 0, to: 1 },
						scaleY: { from: 0, to: 1 },
						duration: appearDuration,
						ease: 'Back.easeOut',
						onComplete: () => {
							button.setInteractive();
						}
					});
					this.time.delayedCall(appearDuration, () => {
						this.debugMode ? this.debugUIButton.setAlpha(1) : this.debugUIButton.setAlpha(0.5);
					});
				} else {
					this.tweens.add({
						targets: button,
						alpha: 0,
						duration: appearDuration,
						ease: 'Power2',
					});
				}
			});
		}
	}

	pauseGame(isPaused){
		webSocketConnection.pauseGame(isPaused);
		//isPaused ? this.scene.pause() : this.scene.resume();
		//isPaused ? this.time.timeScale = 0 : this.time.timeScale = 1;
		if (isPaused) {
			sound.play("pause");
			sound.pauseSong(true);
			this.speedButton.disableInteractive();
			this.speedButton.setAlpha(0.5);

			this.autoAttackButton.disableInteractive();
			this.autoAttackButton.setAlpha(0.5);

		} else {	
			sound.pauseSong(false);
			this.speedButton.setInteractive();
			this.autoAttackButton.setInteractive();
			this.speedButton.setAlpha(1);
			this.autoAttackButton.setAlpha(1);
		}
	}

	createUI(){

		this.pauseController = new Pause(this);
		this.events.on('game_paused', this.pauseGame, this);
		this.gameButtons = [];

		this.topBarUI = this.add.container(0, 0);

		let topBar = this.add.image(screen.centerX, 0, 'fight_atlas', 'fight_top_bar').setOrigin(0.5, 0);
		topBar.setDisplaySize(screen.width, topBar.height);
		this.topBarUI.add(topBar);

		this.timeUI = this.add.container(0,0);
		this.topBarUI.add(this.timeUI);

		let timeBar = this.add.image(0,0,'fight_atlas','currency_box');
		this.timeUI.add(timeBar);

		let timeIcon = this.add.image(-timeBar.width * 0.5,0,'fight_atlas','hourglass');
		this.timeUI.add(timeIcon);

		let timeText = this.add.bitmapText(timeBar.displayWidth * 0.05,0,'fgd_font','00:00',27).setOrigin(0.5);
		this.timeUI.add(timeText);
		this.timeUI.timeText = timeText;

		this.timeUI.setPosition(timeBar.displayWidth, topBar.height * 0.25);

		this.coinsUI = this.add.container(0,0);
		this.topBarUI.add(this.coinsUI);

		let coinsBar = this.add.image(0,0,'fight_atlas','currency_box');
		this.coinsUI.add(coinsBar);

		let coinsIcon = this.add.image(-coinsBar.width * 0.5,0,'fight_atlas','gold logo').setScale(0.6);
		this.coinsUI.add(coinsIcon);

		let coinsText = this.add.bitmapText(coinsBar.displayWidth * 0.2, 0, 'fgd_font', '0', 32).setOrigin(0, 0.5);
		this.coinsUI.add(coinsText);
		this.coinsUI.coinsText = coinsText;

		this.coinsUI.setPosition(screen.width - coinsBar.displayWidth * 0.7, topBar.height * 0.25);

		this.bottomUI = this.add.container(0,0);

		let bottomBar = this.add.image(screen.centerX, screen.height, 'fight_atlas', 'fight_bottom_bar').setOrigin(0.5, 1).setScale(1,0.7);
		bottomBar.setDisplaySize(screen.width, bottomBar.height);
		this.bottomUI.add(bottomBar);

		this.screenButton = this.add.rectangle(screen.centerX,screen.centerY,screen.width,screen.height,0x000000);
		this.screenButton.alpha = 0;
		this.screenButton.on('pointerdown',()=>{
			if(this.readyToSendAction){
				webSocketConnection.finishedTask();
				this.readyToSendAction = false;
				this.screenButton.disableInteractive();
				this.screenButton.alpha = 0;
			}
		});
		this.bottomUI.add(this.screenButton);

		this.pauseButton = this.add.image(50, screen.height - bottomBar.height * 0.67, 'fight_atlas', 'pause').setInteractive();
		this.pauseButton.x = this.pauseButton.displayWidth * 0.65;
		this.pauseButton.disabledTime = 500;
		this.pauseButton.on('pointerdown', () => {
			sound.play("click");
			this.pauseButton.disableInteractive();
			this.pauseController.pauseGame();
			this.gameUtils.scaleButton(this.pauseButton);
			this.time.delayedCall(this.pauseButton.disabledTime, () => {
				this.pauseButton.setInteractive();
			});
			this.pauseController.isGamePaused() ? this.pauseButton.setTexture('fight_atlas','play_button') : this.pauseButton.setTexture('fight_atlas','pause');
		});
		this.gameButtons.push(this.pauseButton);
		this.bottomUI.add(this.pauseButton);

		this.speedButton = this.add.container(screen.width - 50, screen.height - bottomBar.height * 0.67);
		let speedButtonImage = this.add.image(0, 0, 'fight_atlas', 'fight_button');
		this.speedButton.add(speedButtonImage);

		let speedButtonText = this.add.bitmapText(0, -speedButtonImage.height * 0.1, 'fgd_font', 'x1', 60).setOrigin(0.5);
		this.speedButton.add(speedButtonText);
		this.speedButton.speedText = speedButtonText;

		this.speedButton.x = screen.width - speedButtonImage.displayWidth * 0.65;
		this.speedButton.setSize(speedButtonImage.width, speedButtonImage.height);
		this.speedButton.disabledTime = 1000;
		this.speedButton.on('pointerdown', () => {
			sound.play("click");
			this.speedButton.disableInteractive();
			this.gameUtils.scaleButton(this.speedButton);
			this.time.delayedCall(this.speedButton.disabledTime, () => {
				this.speedButton.setInteractive();
			});

			this.changeGameSpeed();

		});
		this.gameButtons.push(this.speedButton);
		this.bottomUI.add(this.speedButton);

		this.autoAttackButton = this.add.image(screen.width - 50, screen.height - bottomBar.height * 0.67, 'fight_atlas', 'auto_attack').setInteractive();
		this.autoAttackButton.active = true;
		this.autoAttackButton.x = this.speedButton.x - this.autoAttackButton.displayWidth * 1.05;
		this.autoAttackButton.on('pointerdown', () => {
			sound.play("click");
			this.autoAttackButton.disableInteractive();
			this.gameUtils.scaleButton(this.autoAttackButton, () => {
				this.autoAttackButton.setInteractive();
			});
			this.autoAttackButton.active = !this.autoAttackButton.active;
			if (this.autoAttackButton.active) {
				this.autoAttackButton.setAlpha(1);
			} else {
				this.autoAttackButton.setAlpha(0.5);
			}
			sound.play("click");
			this.manualMode = !this.manualMode;
			playerProgressData.setPlayerDataValue('manualMode', this.manualMode);
			if(!this.manualMode && this.readyToSendAction){
				this.finishedTask();
				this.readyToSendAction = false;
			}
		});
		this.gameButtons.push(this.autoAttackButton);

		this.debugUIButton = this.add.container(this.autoAttackButton.x - this.autoAttackButton.displayWidth * 1.05, this.autoAttackButton.y).setAlpha(0.5);
		let buttonImage = this.add.image(0, 0, 'fight_atlas', 'fight_button');
		this.debugUIButton.add(buttonImage);

		let buttonText = this.add.bitmapText(0, 0, 'fgd_font', 'DEBUG', 25, 1).setOrigin(0.5);
		this.debugUIButton.add(buttonText);

		this.debugUIButton.setSize(buttonImage.width, buttonImage.height);
		this.debugUIButton.setInteractive();
		this.debugUIButton.on('pointerdown',()=>{
			sound.play("click");
			/* this.debugUIButton.disableInteractive();
			this.gameUtils.scaleButton(this.debugUIButton,()=>{
				this.debugUIButton.setInteractive();
			}); */
			this.debugMode = !this.debugMode;
			this.debugMode ? this.debugUIButton.setAlpha(1) : this.debugUIButton.setAlpha(0.5);
			this.events.emit('turn_debug_ui', this.debugMode);
			playerProgressData.setPlayerDataValue('debugMode', this.debugMode);
		},this);
		this.gameButtons.push(this.debugUIButton);
	}

	changeGameSpeed(speed){
		let newSpeed = this.time.timeScale == 1 ? 2 : 1;
		if(speed) {
			newSpeed = speed;
		}
		this.events.emit('change_speed', newSpeed);
		this.time.timeScale = newSpeed;

		this.speedButton.speedText.setText('x' + newSpeed);
	}

	showAttackButtons(active){
		if(active) {
			this.attackButtons.forEach((button)=>{
				this.tweens.add({
					targets:button,
					scaleX:{from:0,to:1},
					scaleY:{from:0,to:1},
					duration:300,
					ease:'Back.easeOut'
				});
			});
			this.time.delayedCall(300,()=>{
				this.gameUtils.activateScreenButtons(this.attackButtons, true);
			});
		} else {
			this.gameUtils.activateScreenButtons(this.attackButtons, false);
			this.attackButtons.forEach((button)=>{
				this.tweens.add({
					targets:button,
					scaleX:{from:1,to:0},
					scaleY:{from:1,to:0},
					duration:300,
					ease:'Back.easeIn'
				});
			});
		}
	}

	createAttackButtons(){

		let buttons = [{text:"Default\nAttack",type:"Default Ability"},{text:"Auto\nAttack",type:"Auto-Ability 1"}];
		this.attackButtons = [];
		buttons.forEach((button,index)=>{

			let buttonContainer = this.add.container(screen.width - 250 + 150 * index, screen.height - 150);

			let buttonImage = this.add.image(0,0,'assets','attack_button');
			buttonContainer.add(buttonImage);

			let buttonText = this.add.bitmapText(0,0,'grobold',button.text,20).setOrigin(0.5).setCenterAlign();
			buttonContainer.add(buttonText);

			buttonContainer.setSize(buttonImage.width,buttonImage.height);
			buttonContainer.setInteractive();
			buttonContainer.on('pointerdown',()=>{
				sound.play("click");
				this.showAttackButtons(false);
				let attacker = this.heroesList[0];
				let target = this.enemiesList[0];
				attacker.attack(target, target.healthPoints - this.localDamage, ()=>{
					if(!this.matchEnded) {
						this.sendAction();
					}
					this.isAttacking = false;
				},sound, button.type);
			},this);
			this.attackButtons.push(buttonContainer);
			buttonContainer.setScale(0);
		});
		this.gameUtils.activateScreenButtons(this.attackButtons, false);
	}

	createBlackFade(){
		this.blackFade = this.add.rectangle(screen.centerX,screen.centerY,screen.width,screen.height,0x000000)
		this.blackFade.alpha = 0;
	}

	createBackground(){
		this.background = new FightBackground(this, 'background');
		this.background.originalPositionY = this.background.background.y;
	}

	createHeroes(){
		this.heroesList = [];
		this.enemiesList = [];

		this.charactersContainer = this.add.container();
	}

	getCardsScale(numberOfCards, offset){

		let cardWidth = this.gameUtils.getImageWidth('hero_box','characters_atlas');
		if (cardWidth * numberOfCards * offset > screen.width) {
			return screen.width / (cardWidth * numberOfCards * offset);
		} else {
			return 1;
		}

	}

	setHeroes(matchData){

		this.attackController = new AttackController(this);
		let charactersData = this.cache.json.get('charactersData').characters;
		let healthBarOffset = {x:40, y:-200};
		let attackBarOffset = {x:40, y:-185};

		const centerCharacters = screen.centerY;
		let heroesPositions = gameConfig.getCharacterPositions();

		let cardsPosition = {x:0, y: screen.height - screen.height * 0.117, offset: 1.1};
		
		let cardsScale = this.getCardsScale(5,cardsPosition.offset);
		console.log(cardsScale + ' cards scale');
		for (let i = 0; i < matchData.player_units.length && matchData.player_units[i].available; i++){
			let unitData = matchData.player_units[i];
			let heroData = charactersData.find(character => character.id == unitData.type);
			let heroCard = new HeroCard(this, {x: cardsPosition.x, y: cardsPosition.y}, heroData.imageName ? heroData.imageName : heroData.fileName, 
				new HealthBar(this, {x: 0, y:0}, 1.3, true, unitData.health_points), new AttackBar(this, {x: 0, y:0}, 1.3, true, 100));
			heroCard.setScale(cardsScale);
			heroCard.x+= heroCard.displayWidth * 0.5 * cardsPosition.offset;
			cardsPosition.x+= heroCard.displayWidth * cardsPosition.offset;
			let hero = new Fighter(this, {x:screen.centerX + heroesPositions[i].x, y:centerCharacters + heroesPositions[i].y}, unitData, 
				heroData, new HealthBar(this,{x:-healthBarOffset.x, y:-heroData.height}, 1, true, unitData.health_points), 
				new AttackBar(this,{x:-attackBarOffset.x, y:-heroData.height + 19}, 1, true, 100), true, this.attackController, heroCard,
				this.instantiatedHeroes.find((hero) => hero.characterId == unitData.type && hero.alpha == 0));
			this.bottomUI.add(heroCard);
			this.heroesList.push(hero);
			this.charactersContainer.add(hero);
		}

		for(let i = 0; i < matchData.opponent_units.length && matchData.opponent_units[i].available; i++){
			let unitData = matchData.opponent_units[i];
			let enemyData = charactersData.find(character => character.id == unitData.type);
			let enemy = new Fighter(this, {x:screen.centerX - heroesPositions[i].x, y: centerCharacters + heroesPositions[i].y}, unitData, 
				enemyData, new HealthBar(this, {x:healthBarOffset.x, y:-enemyData.height}, 1, false, unitData.health_points), 
				new AttackBar(this, {x:attackBarOffset.x, y:-enemyData.height + 19}, 1, false, 100), false, this.attackController, null, 
				this.instantiatedHeroes.find((hero) => hero.characterId == unitData.type && hero.alpha == 0));
			//enemy.x+= screen.centerX;
			this.enemiesList.push(enemy);
			this.charactersContainer.add(enemy);
		}
	}

	hideSandboxUI(){
		this.topSandboxUI.alpha = 0;
		this.bottomSandboxUI.alpha = 0;
		this.topBarUI.alpha = 1;
		this.bottomUI.alpha = 1;
		this.background.background.y = this.background.originalPositionY;
		this.instantiatedHeroes.forEach((hero)=>{
			hero.alpha = 0;
		});
	}

	sendSelectedHeroes(){

		let charactersData = this.cache.json.get('charactersData').characters;
		let heroes = [];
		this.sandBoxSelectedHeroes.forEach((hero)=>{
			if (hero != null) {
				let heroData = charactersData.find(character => character.name == hero);
				heroes.push(heroData.id);
			}
		});

		let enemies = [];
		this.sandBoxSelectedEnemies.forEach((enemy)=>{
			if (enemy != null) {
				let enemyData = charactersData.find(character => character.name == enemy);
				enemies.push(enemyData.id);
			}
		});

		console.log('heroes selected', heroes);
		console.log('enemies selected', enemies);
		webSocketConnection.sendMessage(JSON.stringify({ 'type': 'sandbox-select-heroes-and-enemies', 'heroes': heroes, 'enemies': enemies }));
	}

	createSandboxUI(){
		this.topSandboxUI = this.add.container(0,0);
		this.bottomSandboxUI = this.add.container(0,screen.height);
	}

	showSandboxUI(){

		this.topBarUI.alpha = 0;
		this.bottomUI.alpha = 0;

		let backgroundOffset = screen.height * 0.07;
		this.background.background.y-= backgroundOffset;

		let topBar = this.add.image(0,0,'fight_atlas','top_selection_panel').setOrigin(0,0);
		topBar.displayWidth = screen.width;
		this.topSandboxUI.add(topBar);

		let vsText = this.add.image(screen.centerX, topBar.displayHeight * 0.55, 'fight_atlas', 'vs_logo');
		this.topSandboxUI.add(vsText);

		let containersOffset = 275;
		let pivotX = screen.centerX - containersOffset;
		for (let i = 0; i < 2; i++){
			let name = i == 0 ? "playerPointsContainer" : "enemyPointsContainer";
			this[name] = this.add.container(pivotX + containersOffset * 2 * i, topBar.displayHeight * 0.3);

			let pointsBar = this.add.image(0,0,'fight_atlas','power_box');
			this[name].add(pointsBar);

			let pointsText = this.add.bitmapText(pointsBar.displayWidth * 0.15,-pointsBar.displayHeight * 0.05,'fgd_font','15k',45).setOrigin(0.5);
			this[name].add(pointsText);

			let powerLogo = this.add.image(-pointsBar.displayWidth * 0.2,0,'map_atlas','power_logo');
			this[name].add(powerLogo);
			this.topSandboxUI.add(this[name]);
		}

		let bottomImage = this.add.image(0,0,'fight_atlas','bottom_selection_panel').setOrigin(0,1);
		bottomImage.displayWidth = screen.width;
		this.bottomSandboxUI.add(bottomImage);

		let heroesBackFrame = this.add.image(screen.centerX, -bottomImage.displayHeight * 0.89, 'results_atlas', 'rewards_win_box');
		heroesBackFrame.displayWidth = screen.width * 0.9;
		heroesBackFrame.displayHeight*= 0.9;
		this.bottomSandboxUI.add(heroesBackFrame);

		let heroesText = this.add.bitmapText(screen.centerX - heroesBackFrame.displayWidth * 0.45, heroesBackFrame.y - 5, 'fgd_stroke', 'Heroes', 45).setOrigin(0,0.5);
		this.bottomSandboxUI.add(heroesText);
		this.bottomSandboxUI.heroesText = heroesText;

		let battleButton = this.add.container(screen.centerX, -bottomImage.displayHeight * 1.1);
		let battleButtonImage = this.add.image(0,0,'fight_atlas','button');
		battleButton.add(battleButtonImage);
		
		let battleButtonText = this.add.bitmapText(0, -10, 'fgd_stroke', 'Continue', 45).setOrigin(0.5);
		battleButton.add(battleButtonText);
		battleButton.setSize(battleButtonImage.width,battleButtonImage.height);
		battleButton.setInteractive();
		battleButton.setAlpha(0);
		battleButton.on('pointerdown',()=>{
			battleButton.disableInteractive();
			sound.play("bump");
			this.showBattleButton(false);
			this.gameUtils.scaleButton(battleButton,()=>{
				if (this.selectHeroesButtons.alpha == 1) {
					this.selectHeroesButtons.setAlpha(0);
					this.selectEnemiesButtons.setAlpha(1);
					battleButtonText.setText('Start Battle');
					heroesText.setText('Enemies');
					battleButton.setInteractive();
				} else {
					this.showBattleButton(false);
					this.sendSelectedHeroes();
					this.time.delayedCall(500,()=>{
						this.hideSandboxUI();
						this.startMatch();
						this.effects.showFade(500,()=>{
							this.prepareFighters();
						});
					});
				}
			});
		});
		this.bottomSandboxUI.add(battleButton);
		this.bottomSandboxUI.battleButton = battleButton;

		const sandboxData = playerProgressData.getPlayerDataValue('sandboxData');
		if (sandboxData == null) {
			console.error('sandbox data not found');
			this.gameUtils.showWarning('Sandbox data not found');
			return;
		}
		let selectableHeroes = sandboxData.available_heroes;
		let selectableEnemies = sandboxData.available_enemies;
		
		let charactersData = this.cache.json.get('charactersData').characters;
		
		let initialX = 135;
		let pivotY = bottomImage.displayHeight * 0.68;

		this.selectHeroesButtons = this.add.container(0,0);
		this.bottomSandboxUI.add(this.selectHeroesButtons);
		
		this.sandBoxSelectedHeroes = [];	
		this.instantiatedHeroes = [];
		this.createSelectableHeroes({x:initialX,y:pivotY}, selectableHeroes, charactersData, this.selectHeroesButtons, this.sandBoxSelectedHeroes);

		this.selectEnemiesButtons = this.add.container(0,0);
		this.selectEnemiesButtons.setAlpha(0);
		this.bottomSandboxUI.add(this.selectEnemiesButtons);
		
		this.sandBoxSelectedEnemies = [];	
		this.createSelectableHeroes({x:initialX,y:pivotY}, selectableEnemies, charactersData, this.selectEnemiesButtons, this.sandBoxSelectedEnemies, true);

		let deleteSelectedButton = this.add.container(this.gameUtils.getImageWidth('trash_button','fight_atlas') * 0.7, -bottomImage.displayHeight * 1.1).setAlpha(0);
		let deleteSelectedImage = this.add.image(0,0,'fight_atlas','trash_button').setScale(0.9);
		deleteSelectedButton.add(deleteSelectedImage);
		deleteSelectedButton.setSize(deleteSelectedImage.displayWidth,deleteSelectedImage.displayHeight);
		deleteSelectedButton.setInteractive();
		deleteSelectedButton.on('pointerdown',()=>{
			sound.play("gate");
			deleteSelectedButton.disableInteractive();
			this.gameUtils.scaleButton(deleteSelectedButton);
			this.sandBoxSelectedEnemies.forEach((enemyName,index)=>{
				if (enemyName != null) {
					let character = this.instantiatedHeroes.find(character => character.characterName == this.sandBoxSelectedEnemies[index] 
						&& !character.removed && character.x > screen.centerX);
					character.removed = true;
					this.sandBoxSelectedEnemies[index] = null;
					this.hideHeroOnBattlefield(character);
				}
			});
			this.selectEnemiesButtons.list.forEach((enemyButton)=>{
				enemyButton.hero = null;
				enemyButton.selected = false;
				enemyButton.heroSelectedImage.setAlpha(0);
			});
			this.tweens.add({
				targets:[deleteSelectedButton, battleButton],
				alpha:0,
				duration:200,
				onComplete:()=>{
					deleteSelectedButton.setInteractive();
				}
			});
		});
		this.bottomSandboxUI.add(deleteSelectedButton);
		this.bottomSandboxUI.deleteSelectedButton = deleteSelectedButton;
	}

	createSelectableHeroes(position, heroesList, charactersData, container, selectedList, isEnemy = false){
		let charactersOffset = screen.height * 0.1;
		let heroesPositions = gameConfig.getCharacterPositions();
		let cardsOffset = this.gameUtils.getImageWidth('hero_selection_box','characters_atlas');
		const initialPositionX = position.x;
		heroesList.forEach((heroName,index)=>{
			let heroData = charactersData.find(character => character.id == heroName);
			
			if (heroData == null){
				console.warn('hero not available', heroName);
				return;
			}
			if ((heroData.team == "heroes" && isEnemy) || (heroData.team == "enemies" && !isEnemy)) {
				return;
			}
			let heroButton = this.add.container(position.x, -position.y);

			position.x+= cardsOffset;
			if(position.x > screen.width - cardsOffset * 0.6){
				position.x = initialPositionX;
				position.y-= cardsOffset;
			}
			let heroImage = this.add.image(0,0,'characters_atlas', 'selection_' + (heroData.imageName ? heroData.imageName : heroData.fileName));
			heroButton.add(heroImage);
			let heroImageFrame = this.add.image(0,0,'characters_atlas', 'hero_selection_box');
			heroButton.add(heroImageFrame);

			let heroElement = this.add.image(-heroImage.displayWidth * 0.5, -heroImage.displayHeight * 0.5, 'characters_atlas', heroData.element).setScale(0.3).setOrigin(0);
			heroButton.add(heroElement);

			let heroLevel = this.add.bitmapText(-heroImage.displayWidth * 0.45, heroImage.displayHeight * 0.45, 'fgd_font', 'Lvl 1', 25).setOrigin(0,1);
			heroButton.add(heroLevel);

			let heroSelected = this.add.image(0,0,'characters_atlas','checkmark_hero').setAlpha(0);
			heroButton.add(heroSelected);
			heroButton.heroSelectedImage = heroSelected;

			container.add(heroButton);
			heroButton.setSize(heroImageFrame.width,heroImageFrame.height);
			heroButton.setInteractive();
			heroButton.selected = false;
			heroButton.on('pointerdown',()=>{

				heroButton.disableInteractive();
				this.gameUtils.scaleButton(heroButton, ()=>{
					heroButton.setInteractive();
				});
				if (isEnemy) {
					if (selectedList.length >= 5 && selectedList.indexOf(null) == -1) {
						sound.play("error");	
						return;
					}
				} else {
					if (selectedList.length >= 5 && !heroButton.selected && selectedList.indexOf(null) == -1) {
						sound.play("error");	
						return;
					}
				}
				sound.play("quick-whoosh");
				heroButton.selected = !heroButton.selected;
				heroSelected.setAlpha(heroButton.selected ? 1 : 0);
				if(heroButton.selected || isEnemy){
					if (isEnemy) {
						if(this.bottomSandboxUI.deleteSelectedButton.alpha == 0) {
							this.tweens.add({
								targets:this.bottomSandboxUI.deleteSelectedButton,
								alpha:1,
								duration:300,
							});
						}
						heroSelected.setAlpha(1);
					}
					let index = selectedList.indexOf(null);
					if (index == -1) {
						index = selectedList.length;
					}
					selectedList[index] = heroData.name;
					let hero;
					hero = this.instantiatedHeroes.find(character => (character.characterName == heroData.name && character.alpha == 0));
					let heroPosition = isEnemy ? {x:screen.centerX - heroesPositions[index].x, y:screen.centerY + heroesPositions[index].y - charactersOffset} :
							{x:screen.centerX + heroesPositions[index].x, y:screen.centerY + heroesPositions[index].y - charactersOffset};
					if (hero == null) {
						hero = new SpineAnimation(this, heroPosition.x, heroPosition.y, heroData.fileName, 'idle', true).setScale(heroData.scale);
						if (heroData.skin) {
							hero.setSkin(heroData.skin);
						}
						hero.characterName = heroData.name;
						hero.characterId = heroData.id;
						this.instantiatedHeroes.push(hero);
						if (isEnemy) {
							hero.scaleX = heroData.rightFacing ? -hero.scaleX : hero.scaleX
						} else {
							hero.scaleX = heroData.rightFacing ? hero.scaleX : -hero.scaleX;
						}
					} else {
						hero.setPosition(heroPosition.x, heroPosition.y);
						hero.setAlpha(1);
					}
					heroButton.hero = hero;
					this.showHeroOnBattlefield(hero);
					if (this.bottomSandboxUI.battleButton .alpha != 1) {
						this.showBattleButton(true);
					}
				} else {
					selectedList[selectedList.indexOf(heroData.name)] = null;
					let hero = heroButton.hero;
					heroButton.hero = null;
					this.hideHeroOnBattlefield(hero);
				}
			});
		});
	}

	showBattleButton(active){
		this.tweens.add({
			targets:this.bottomSandboxUI.battleButton,
			alpha:active ? 1 : 0,
			duration:300,
			ease:'Back.easeOut',
		});

	}

	showHeroOnBattlefield(hero){
		this.tweens.add({
			targets:hero,
			scaleX:{from:0,to:hero.scaleX},
			scaleY:{from:0,to:hero.scaleY},
			duration:300,
			ease:'Back.easeOut'
		})
	}

	hideHeroOnBattlefield(hero){
		let originalScale = {x:hero.scaleX,y:hero.scaleY};
		this.tweens.add({
			targets:hero,
			scaleX:{from:hero.scaleX,to:0},
			scaleY:{from:Math.abs(hero.scaleY),to:0},
			duration:300,
			ease:'Back.easeIn',
			onComplete:()=>{
				hero.setAlpha(0);
				hero.setScale(originalScale.x,originalScale.y);
				hero.removed = false;
			}
		})
	}

	prepareFighters(){

		/* let minDuration = 2000;
		let maxDuration = 3000;
		this.heroesList.forEach((hero)=>{
			hero.moveToStart(Phaser.Math.Between(minDuration,maxDuration));
		});

		this.enemiesList.forEach((enemy)=>{
			enemy.moveToStart(Phaser.Math.Between(minDuration,maxDuration));
		}); */

		let delay = 0;
		let delayStep = 100;
		this.charactersContainer.list.forEach((hero)=>{
			this.time.delayedCall(delay,()=>{
				hero.startCharacter();
			});
			delay += delayStep;
		});

		this.time.delayedCall(delay,()=>{
			this.startFight();
		});
	}

	attackDone(){
		this.isAttacking = false;
		if (!this.matchEnded) {
			this.finishedTask();
		}
	}

	fighterAttack(message){
		
		let attacker, target;
		this.isAttacking = true;
		let isHeroAttacking = message.from_unit.includes('Hero');

		attacker = this.heroesList.find(hero=> hero.fighterName == message.from_unit);
		if (!attacker){
			attacker = this.enemiesList.find(enemy => enemy.fighterName == message.from_unit);
			isHeroAttacking = false;
		}
		target = this.heroesList.find(hero => hero.fighterName == message.to_unit);
		if (!target){
			target = this.enemiesList.find(enemy => enemy.fighterName == message.to_unit);
		}

		if (!attacker || !target) {
			if (!attacker) {
				console.error('Attacker ' + message.from_unit + ' not found');
			}
			if (!target) {
				console.error('Target ' + message.to_unit + ' not found');
			}
			this.attackDone();
			return
		}	

		this.charactersContainer.bringToTop(attacker);
		
		attacker.updateAttack(100);
		attacker.attack(target, message.health_points, ()=>{
			this.attackDone();
		},message.ability.class);
		
	}

	getDamage(message,isHeroAttacking){
		let target, damage;
		if(!isHeroAttacking) {
			target = this.matchData.player_units.find(unit => unit.class == message.to_unit);
			damage = target.health_points - message.health_points;
		} else {
			target = this.matchData.opponent_units.find(unit => unit.class == message.to_unit);
			damage = target.health_points - message.health_points;
		}
		this.localDamage = damage;
	}

	sandboxAttack(isHeroAttacking, message){
		let attacker, target;
		this.getDamage(message,isHeroAttacking);
		if(isHeroAttacking) {
			this.showAttackButtons(true);
		} else {
			attacker = this.enemiesList[0];
			target = this.heroesList[0];
			attacker.attack(target, target.healthPoints - this.localDamage, ()=>{
				this.attackDone();
			},sound,message.ability.class);
		}
	}

	findCharacterByName(name){
		let character = null;
		character = this.heroesList.find(hero => hero.fighterName == name);
		if (!character) {
			character = this.enemiesList.find(enemy => enemy.fighterName == name);
		}
		return character
	}

	startFight(){
		this.showGameButtons(true,false);
		sound.play("fight");
		/* this.counterText.startCounter(1, ()=>{
			
			this.finishedTask();
		}); */
	}

	update(timer,delta){

	
	}

	show(){
		this.restartAssets();
		this.animateScene();
	}

	createFakeHero(){
		this.fakeHero = new SpineAnimation(this, -screen.width, -screen.height, 'water_asian').setScale(0.4);
	}

	animateScene(){

		this.showGameButtons(false,true);
		if (this.isSandbox) {
			this.showSandboxUI();
			this.time.delayedCall(500,()=>{
				this.createFakeHero();
			});
		} else {
			this.startMatch();
		}
		
		//this.scene.get("TransitionScene").events.emit("open_transition",1000,()=>{
		this.effects.showFade(500,()=>{
			if (this.isSandbox) {
				
			} else {
				this.prepareFighters();
			}
			sound.playSong("fantasy_ballad");
		});
		sound.setMusicVolume(0.3);
	}

	restartAssets(){
		this.score = 0;
	}
}