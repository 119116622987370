export default class AttackBar extends Phaser.GameObjects.Container{

    constructor(scene,position,scaleValue,rightLooking = false,initialPoints){
        super(scene, position.x, position.y);
        this.tweens = scene.tweens;
        
        let heroAttackBar = scene.add.image(0,0,'fight_atlas','time_bar').setOrigin(1,0.5);
		this.add(heroAttackBar);

		let attackBar = scene.add.image(-heroAttackBar.width * 0.035,0,'fight_atlas','time_fill').setOrigin(1,0.5);
		this.add(attackBar);
        this.yellowBar = attackBar;

        this.originalScale = {};
        this.originalScale.x = rightLooking ? -scaleValue : scaleValue;
        this.originalScale.y = scaleValue;
        this.setScale(this.originalScale.x, this.originalScale.y);

        this.initialPoints = initialPoints;
    }

    setScaleY(value){
        this.originalScale.y = value;
        this.setScale(this.originalScale.x, this.originalScale.y);
    }

    restartBar(){
        this.alpha = 1;
        this.tweens.add({
            targets:this,
            scaleX:{from:0,to:this.originalScale.x},
            scaleY:Math.abs(this.originalScale.y),
            ease:'Back.easeOut',
            duration:500,
        });

        this.tweens.add({
            targets:this.yellowBar,
            scaleX:{from:0,to:1},
            duration:500,
        });

    }

    updateBar(value){
        let scaleValue = value / this.initialPoints;
        if (scaleValue > 1) {
            scaleValue = 1;
        }
        if (this.updateBarTween) {
            this.updateBarTween.stop();
            this.updateBarTween = null;
        }
		this.updateBarTween = this.tweens.add({
			targets:this.yellowBar,
			scaleX:scaleValue,
			duration:500,
		});
    }
}