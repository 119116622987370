export default class SpineAnimation extends SpinePlugin.SpineGameObject{
    constructor(scene, x, y, key, animationName, loop = true) {
        super(scene, scene.spine, x, y, key, animationName, loop);
        scene.sys.displayList.add(this);
        scene.sys.updateList.add(this);
    }

    playAnimation(animationName, loop = false, onComplete = null) {
        if (onComplete) {
            let completeEvent = ()=>{
                onComplete();
                this.state.removeListener(completeListener);
            }
            const completeListener = {complete: completeEvent};
            this.state.addListener(completeListener);
        }
        return this.setAnimation(0, animationName, loop);
    }

    playAnimationWithEvents(animationName, callback) {
        let trackEntry;
        if (typeof animationName === 'string') {
            trackEntry = this.playAnimation(animationName);
        } else {
            trackEntry = animationName
        }

        let listenEvent = (entry,event)=>{
            callback(event, ()=>{
                trackEntry.listener = null;
            });
        }
        const eventListener = {event: listenEvent};
		trackEntry.listener = eventListener;
    }

    setAnimationEvents(trackEntry, callback) {
        this.playAnimationWithEvents(trackEntry, callback);
    }

    setSkin(skinName) {
        this.setSkinByName(skinName);
    }

    stopAnimation() {
        this.clearTrack(0);
    }

    positionBone(object, aimBoneName, offset = {x:0, y:0}) {
		let aimBone = this.skeleton.findBone(aimBoneName);
		let localPosition = this.worldToLocal(this.parentContainer != null ? this.parentContainer : this, object.x + offset.x, object.y + offset.y);
        let scaleFactor = 1 / this.scaleX;
		aimBone.x = localPosition.x * scaleFactor;
		aimBone.y = -localPosition.y * Math.abs(scaleFactor);
        this.skeleton.updateWorldTransform();
	}

    worldToLocal(spineObject, worldX, worldY) {
        let localX = worldX - spineObject.x;
        let localY = worldY - spineObject.y;

        let rotation = spineObject.rotation; 
        let cos = Math.cos(-rotation);  
        let sin = Math.sin(-rotation);

        let rotatedX = localX * cos - localY * sin;
        let rotatedY = localX * sin + localY * cos;

        let scaledX = rotatedX / spineObject.scaleX;
        let scaledY = rotatedY / spineObject.scaleY;

        return { x: scaledX, y: scaledY };
    }

    playAnimationAfter(animationName, secondAnimationName, loop = false) {
        this.playAnimation(animationName, false, ()=>{
            this.playAnimation(secondAnimationName, loop);
        });
    }
}