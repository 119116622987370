export default class Debuffs extends Phaser.GameObjects.Container{

    constructor(scene,position, maxNumber, offset, rightLooking, scaleToUse){
        super(scene, position.x, position.y);
        
        this.debuffColor = 0xbd1750;
        this.buffColor = 0x0090a6;
        this.scene = scene;
        this.iconsNumber = 0;
        this.scaleToUse = scaleToUse;
        this.createIcons(maxNumber);
        this.iconOffset = rightLooking ? offset : -offset;
        this.iconOffset*= this.scaleToUse;
    }

    createIcons(maxNumber){
        for (let i = 0; i < maxNumber; i++) {
            let iconContainer = this.scene.add.container(0,0).setAlpha(0);
            iconContainer.iconActive = false;
            this.add(iconContainer);

            let iconImage = this.scene.add.image(0,0,'fight_atlas','bleed').setScale(this.scaleToUse);
            iconContainer.add(iconImage);
            iconContainer.iconImage = iconImage;

            let durationTextOverline = this.scene.add.bitmapText(iconImage.displayWidth * 0.2, -iconImage.displayHeight * 0.6, 'fgd_font', '0', 25).setTint(this.buffColor);
            iconContainer.add(durationTextOverline);

            let durationText = this.scene.add.bitmapText(durationTextOverline.x,  durationTextOverline.y, 'fgd_font', '0', 20);
            iconContainer.add(durationText);

            iconContainer.overLineText = durationTextOverline;
            iconContainer.iconTexts = [durationText, durationTextOverline];
        }
    }

    getIconContainer(){
        return this.list.find(iconContainer => iconContainer.iconActive == false);
    }

    setText(iconContainer, duration){
        iconContainer.iconTexts.forEach(text => text.setText(duration));
    }

    setIcon(tag, duration){
        if (this.iconsNumber >= this.list.length) {
            return;
        }
        let iconContainer = this.getIconContainer();
        iconContainer.iconImage.setTexture('fight_atlas',tag.replace(/-/g, '_'));
        duration ? this.setText(iconContainer, duration) : this.setText(iconContainer, '');
        tag.includes('boost') ? iconContainer.overLineText.setTint(this.buffColor) : iconContainer.overLineText.setTint(this.debuffColor);

        iconContainer.setAlpha(1);
        iconContainer.x = this.iconsNumber * this.iconOffset;
        iconContainer.tag = tag;
        this.iconsNumber++;
        return iconContainer;
    }

    updateIcon(tag, duration){
        let iconContainer = this.list.find(iconContainer => iconContainer.tag == tag);
        if (!iconContainer) {
            iconContainer = this.setIcon(tag, duration);
        }
        this.showIcon(iconContainer);
        this.setText(iconContainer, duration);
        if (duration == 0) {
            this.hideIcon(iconContainer);
        }
        this.orderIcons();
    }

    showIcon(iconContainer){
        iconContainer.iconActive = true;
        this.scene.tweens.add({
            targets:iconContainer,
            scale:{from:0,to:1},
            ease:'Back.easeOut',
            duration:300,
        });
        this.iconsNumber++;
    }

    hideIcon(iconContainer){
        iconContainer.iconActive = false;
        this.scene.tweens.add({
            targets:iconContainer,
            alpha:0,
        });
        this.iconsNumber--;
    }

    orderIcons(){
        let activeIcons = this.list.filter(iconContainer => iconContainer.iconActive == true);
        activeIcons.forEach((iconContainer, index) => {
            iconContainer.x = index * this.iconOffset;
        });
    }
}