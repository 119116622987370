export var assetsManager = function () {

    var preloadAssets = {
        assets : {
            atlases: [
                {
                    name: "loadingAtlas",
                    json: "assets/images/preload/atlas.json",
                    image: "assets/images/preload/atlas.png",
                },
                {   
                    name: "splash_atlas",
                    json: "assets/images/game/splash.json",
                    image: "assets/images/game/splash.png",
                },
            ],
            soundsList:["load_button","drag"],
            soundFormat:'.mp3',
            soundsLocation:'assets/sounds/',
            fonts: [
                {
                    name:"fgd_stroke",
                    image:"assets/images/game/font/fgd_stroke.png",
                    font:"assets/images/game/font/fgd_stroke.fnt",
                },
            ]
        }
    }

    var gameAssets = {
        assets : {
            plugins:[

            ],
            images:[
                {
                    name:"loginBackground",
                    file:"assets/images/game/loginBackground.png",
                },
                {
                    name:"background",
                    file:"assets/images/game/backgrounds/background.png"
                }
            ],
            atlases: [
                {   
                    name: "assets",
                    json: "assets/images/game/assets.json",
                    image: "assets/images/game/assets.png",
                },
                {   
                    name: "vfx_atlas",
                    json: "assets/images/game/vfx.json",
                    image: "assets/images/game/vfx.png",
                },
                {
                    name:"characters_atlas",
                    json:"assets/images/game/characters.json",
                    image:"assets/images/game/characters.png",
                },
                {
                    name:"transition_atlas",
                    json:"assets/images/game/transition.json",
                    image:"assets/images/game/transition.png",
                },
                {
                    name:"fight_atlas",
                    json:"assets/images/game/fight.json",
                    image:"assets/images/game/fight.png",
                }
            ],

            multiatlases:[
                {
                    name:"results_atlas",
                    file:"assets/images/game/results.json",
                    path:"assets/images/game/",
                },
                {
                    name:"map_atlas",
                    file:"assets/images/game/map.json",
                    path:"assets/images/game/",
                }
            ],
            soundsList:["bucket","click2","Game_Start","lose","pop","pop_magic","explode","winwin","beep","gameLose","flesh",
                        "quick-whoosh","coin","minigamesError","cashSound","fire","rainbow","fantasy_ballad","zombieUp","punch","fight","victory",
                        "bump","gate","robotic","beam","you_lose","water_attack","laser","pause","error","click"],
            soundFormat:'.mp3',
            fonts: [
                {
                    name:"grobold",
                    image:"assets/images/game/font/grobold.png",
                    font:"assets/images/game/font/grobold.fnt",
                },
                {
                    name:"unispace_regular",
                    image:"assets/images/game/font/unispace_regular.png",
                    font:"assets/images/game/font/unispace_regular.fnt",
                },
                {
                    name:"fgd_font",
                    image:"assets/images/game/font/Font_FGD.png",
                    font:"assets/images/game/font/Font_FGD.fnt",
                },
            ],
            spritesheets:[
                {
                    name:"bomb",
                    file:"assets/images/game/spritesheets/ss_bomb.png",
                    size:{x:346,y:334},
                    frames:17,
                },
                {
                    name:"punchHit",
                    file:"assets/images/game/spritesheets/punch_hit.png",
                    size:{x:199,y:185},
                    frames:18
                },
            ],
            jsons:[
                {
                    name:"charactersData",
                    file:"assets/data/charactersData.json",
                }
            ],
            spines:[
                {
                    name:"chinese_girl",
                    json:"assets/images/game/spines/chinese_girl/skeleton.json",
                    atlas:"assets/images/game/spines/chinese_girl/skeleton.atlas",
                },
                {
                    name:"chinese_girl_ultimate_attack",
                    json:"assets/images/game/spines/chinese_girl/ultimate_attack/skeleton.json",
                    atlas:"assets/images/game/spines/chinese_girl/ultimate_attack/skeleton.atlas",
                },
                {
                    name:"water_asian",
                    json:"assets/images/game/spines/water_asian/skeleton.json",
                    atlas:"assets/images/game/spines/water_asian/skeleton.atlas",
                },
                {
                    name:"ice_guy",
                    json:"assets/images/game/spines/ice_guy/skeleton.json",
                    atlas:"assets/images/game/spines/ice_guy/skeleton.atlas",
                },
                {
                    name:"ice_guy_auto_ability",
                    json:"assets/images/game/spines/ice_guy/auto_ability/skeleton.json",
                    atlas:"assets/images/game/spines/ice_guy/auto_ability/skeleton.atlas",
                },
                {
                    name:"elemental",
                    json:"assets/images/game/spines/elementals/skeleton.json",
                    atlas:"assets/images/game/spines/elementals/skeleton.atlas",
                },
                {
                    name:"chain_girl",
                    json:"assets/images/game/spines/chain_girl/skeleton.json",
                    atlas:"assets/images/game/spines/chain_girl/skeleton.atlas",
                },
                {
                    name:"snake_corruptor",
                    json:"assets/images/game/spines/snake_corruptor/skeleton.json",
                    atlas:"assets/images/game/spines/snake_corruptor/skeleton.atlas",
                },
                {
                    name:"wind_initiator",
                    json:"assets/images/game/spines/wind_initiator/skeleton.json",
                    atlas:"assets/images/game/spines/wind_initiator/skeleton.atlas",
                },
                {
                    name:"wind_initiator_auto_ability",
                    json:"assets/images/game/spines/wind_initiator/auto_ability/skeleton.json",
                    atlas:"assets/images/game/spines/wind_initiator/auto_ability/skeleton.atlas",
                },
                {
                    name:"fire_spear",
                    json:"assets/images/game/spines/fire_spear/skeleton.json",
                    atlas:"assets/images/game/spines/fire_spear/skeleton.atlas",
                },
                {
                    name:"fire_spear_auto_ability",
                    json:"assets/images/game/spines/fire_spear/auto_ability/skeleton.json",
                    atlas:"assets/images/game/spines/fire_spear/auto_ability/skeleton.atlas",
                },
            ],

        }
    }

    function getPreloadAssets(){
        return preloadAssets
    }

    function getGameAssets(){
        return gameAssets
    }

	return{
        getPreloadAssets:getPreloadAssets,
        getGameAssets:getGameAssets,
	}
		
}()