import {assetsManager} from '../libs/assetsManager';
import {gameConfig} from '../libs/gameConfig';
import GameUtils from '../libs/GameUtils.js';
import SceneLoader from '../libs/Sceneloader.js';	

const webSocketConnection = require('../utilities/WebSocketConnection.js');
const sound = require('../libs/Sound.js');
export default class PreloadScene extends Phaser.Scene{

	constructor(){
		super("PreloadScene");
	}

	updateLoadingBar = (event)=>{
		if (this.barFill){
			var loadingStep = (event.progress) * this.barFill.displayWidth;
			var mask = this.maskUsed;
			mask.x = mask.initialX + loadingStep
		}
	}

	preload(){
		this.gameUtils = new GameUtils(this);

		this.sceneloader = new SceneLoader(this);
		this.sceneloader.preload(assetsManager.getPreloadAssets());
	}

	startGame(){
		if (gameConfig.getInfo().skipLoading){
			this.time.delayedCall(150,()=>{
				this.scene.start(gameConfig.getInfo().sceneToTest);
			});
			sound.decode(assetsManager.getGameAssets().assets.soundsList,this);
			webSocketConnection.sendAuthentication(JSON.stringify({ 'username': 'user-name-goes-here' }));
		}else{

			const alphaTime = 500;
			this.barAssets.forEach((asset)=>{
				this.tweens.add({
					targets:asset,
					alpha:0,
					duration:alphaTime,
				});
			});

			this.time.delayedCall(alphaTime,()=>{
				sound.play("drag");
				this.startButton.setAlpha(1);
				this.tweens.add({
					targets:this.startButton,
					scaleX:{from:0,to:1},
					scaleY:{from:3,to:1},
					duration:500,
					ease:'Back.easeOut',
					onComplete:()=>{
						this.startButton.setInteractive();
					}
				});
			});
		}
	}

	showLoad(duration){
		this.loadText.setTexture('splash_atlas','loading_' + this.loadText.imageIndex);
		this.loadText.imageIndex++;
		if (this.loadText.imageIndex > 3){
			this.loadText.imageIndex = 1;
		}
		this.time.delayedCall(duration,this.showLoad.bind(this,duration));
	}
	

	create(){

		sound.decode(assetsManager.getPreloadAssets().assets.soundsList,this)

		//let background = this.add.rectangle(screen.centerX,screen.centerY,screen.width,screen.height,0x33067a);
		let background = this.add.image(screen.centerX,screen.centerY,'splash_atlas','splash_screen');
		if (background.displayWidth < screen.width){
			const originalWidth = background.displayWidth;	
			background.displayWidth = screen.width;
			background.displayHeight = background.displayHeight * (background.displayWidth / originalWidth);
		}

		this.barAssets = [];

		const logo = this.add.image(screen.centerX,screen.centerY - screen.height * 0.3,'splash_atlas','fake_logo');

		let bar = this.add.image(screen.centerX,screen.height * 0.85,'splash_atlas','loading_bar_background');
		let barFill = this.add.image(bar.x, bar.y,'splash_atlas','loading_bar_fill');
		this.barFill = barFill;
		this.barAssets.push(bar);
		this.barAssets.push(barFill);

		this.loadText = this.add.image(barFill.x, barFill.y - barFill.displayHeight * 1.9, 'splash_atlas', 'loading_1').setOrigin(0.5);
		this.loadText.imageIndex = 1;
		let loadDuration = 300;
		this.showLoad(loadDuration);
		this.barAssets.push(this.loadText);

		var mask = this.add.graphics();
		mask.fillStyle(0xffffff);
		mask.beginPath();
		mask.fillRect(barFill.x - barFill.displayWidth * 1.5, barFill.y - barFill.displayHeight * 0.5,barFill.displayWidth,barFill.displayHeight);
		mask.initialX = mask.x;
		mask.endX = mask.initialX - barFill.displayWidth;
		this.maskUsed = mask;
		mask.alpha = 0;

		var maskRect = mask.createGeometryMask()
		barFill.setMask(maskRect)

		var btn = this.add.container(barFill.x, barFill.y - barFill.displayHeight);
		btn.alpha = 0;
		this.startButton = btn;

		var btnActive = this.add.image(0,0,'splash_atlas','button').setScale(1.2);
		btn.add(btnActive);
		btn.on('pointerdown',function(button){
			sound.play("load_button")
			button.disableInteractive()
			this.gameUtils.scaleButton(button,function(){
				webSocketConnection.sendAuthentication(JSON.stringify({ 'username': 'user-name-goes-here' }));
				this.scene.start(gameConfig.getInfo().sceneToTest);
			}.bind(this));
			sound.decode(assetsManager.getGameAssets().assets.soundsList,this);

		}.bind(this,btn),this);
		btn.setSize(btnActive.width,btnActive.height);

		let buttonText = this.add.bitmapText(0,3,'fgd_stroke','Start Game',45,1).setOrigin(0.5);
		btn.add(buttonText);

		this.sceneloader.preload(assetsManager.getGameAssets(), {onLoadFile: this.updateLoadingBar.bind(this), onComplete:this.startGame.bind(this)})
	}

}
