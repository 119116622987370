class WebSocketConnection {
    constructor() {
        this.matchInfo = null;
        this.observer = null;
        this.eventCallbacks = [];
        this.importantCallBackNames = [];
        this.eventsQueue = [];
        this.isBusy = false;
        this.gamePaused = false;
        this.pauseEventsQueue = [];
    }

    setObserver(observer) {
        this.observer = observer;
    }

    setEventCallback(eventName, callback, isImportant = false) {
        this[eventName] = callback;
        if (isImportant) {
            this.importantCallBackNames.push(eventName);
        } else {
            this.eventCallbacks.push(this[eventName]);
        }
    }

    removeEventCallbacks() {
        this.eventCallbacks.forEach(callbackName => {
            this[callbackName] = null;
        });
        this.eventCallbacks = [];
    }

    openSocket(url) {
        this.socket = new WebSocket(url);
        this.socket.onopen = () => {
            console.log('WebSocket connection established.');
        }
        this.addListeners();
    }

    sendAuthentication(data) {
        //JSON.stringify({ 'username': 'user-name-goes-here' })
        this.sendMessage(data);
    }

    sendMessage(message) {
        this.socket.send(message);
    }

    runEvent(msg){

        if (this.gamePaused) {
            this.pauseEventsQueue.push(msg);
            return;
        }

        this.isBusy = true;
        this[msg.type](msg);
        console.log('Running event:', msg.type);
    }

    finishedTask() {

        this.isBusy = false;
        if (this.eventsQueue.length > 0) {
            let msg = this.eventsQueue.shift();
            this.runEvent(msg);
        }
    }

    pauseGame(gamePaused){
        this.gamePaused = gamePaused;
        if (!this.gamePaused) {
            if (this.pauseEventsQueue.length > 0) {
                let msg = this.pauseEventsQueue.shift();
                this.runEvent(msg);
            }
        }
    }

    // message types : authentication, waiting-for-action, match-will-start, turn-did-happen, attack-did-happen, unit-dies, end-game, level-up
    addListeners(){
		this.socket.onmessage = (event) => {
			let msg = JSON.parse(event.data);
            console.log(msg.type + ':', msg);
            if (msg.type && msg.type !== "") {
                if (this[msg.type]) {
                    if (this.isBusy) {
                        this.eventsQueue.push(msg);
                        return;
                    } else {
                        this.runEvent(msg);
                    }
                } else {
                    console.warn('the event ' + msg.type + ' doesnt have any action', msg);
                }
            }
		};

		this.socket.onerror = (event) => {
			console.log('Socket event error. event.data = ', event);
		};

		this.socket.onclose = (event) => {
			console.log('Socket event close. event.data = ', event);
		};
    }

}

module.exports = new WebSocketConnection();