export default class ObservableDataSource {
    constructor() {
        this.observers = [];
        this.previousData = null;
    }

    subscribe(observer) {
        this.observers.push(observer);
    }

    unsubscribe(observer) {
        //this.observers = this.observers.filter(sub => sub !== observer);
        this.observers.pop();
    }

    notify(newData) {
        if (JSON.stringify(newData) !== JSON.stringify(this.previousData)) {
            //console.log('Data has changed. Notifying observers...');
            this.previousData = newData; // Update previous data

            this.observers.forEach(observer => observer(newData));
        } else {
            console.log('Data has not changed. No update needed.');
        }
    }

    receiveData(newData) {
        //console.log('Data received:', newData);
        this.notify(newData);
    }
}
