export default class HeroCard extends Phaser.GameObjects.Container{
    constructor(scene, position, characterName, healthBar, attackBar){
        
        super(scene, position.x, position.y);

        let characterImage = scene.add.image(0,0,'characters_atlas',characterName);
        this.add(characterImage);

        let background = scene.add.image(0,0,'characters_atlas','hero_box');
        this.add(background);
        this.setSize(background.displayWidth, background.displayHeight);

        this.healthBar = healthBar;
        this.healthBar.setPosition(-characterImage.displayWidth * 0.47, characterImage.displayHeight * 0.32);
        this.healthBar.setScaleY(2);
        this.add(this.healthBar);

        this.attackBar = attackBar;
        this.attackBar.setScaleY(1.5);
        this.attackBar.setPosition(-characterImage.displayWidth * 0.47, characterImage.displayHeight * 0.43);
        this.add(this.attackBar);
    }

    updateHealth(remainingHealth){   
        if (typeof remainHealth !== 'Number') {
            remainingHealth = Number(remainingHealth);
        }
        this.healthBar.updateBar(remainingHealth);
    }    

    updateAttack(value){
        this.attackBar.updateBar(value);
    }
}