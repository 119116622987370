export default class Counter extends Phaser.GameObjects.BitmapText{
    constructor(scene, position,font, fontSize,sound){        
        super(scene, position.x, position.y, font, '', fontSize).setOrigin(0.5);
		this.setCenterAlign();
        scene.add.existing(this);
        this.tweens = scene.tweens;
        this.time = scene.time;
        this.sound = sound;
		this.intitialPosition = position;
    }

    startCounter(startNumber,callback){
        let delay = 150;
		for (let i = startNumber; i > 0; i--){
			this.time.delayedCall(delay, ()=>{
				this.sound.play("beep");
				this.setCounter(i);
			});
			delay += 1000;
		}

		this.time.delayedCall(delay, ()=>{
            this.setCounter("Fight!");
			callback();
		});
    }

    setCounter(text, stay = false, position = null){

		if (position) {
			this.setPosition(position.x,position.y);
		} else {
			this.setPosition(this.intitialPosition.x,this.intitialPosition.y);
		}
        this.text = text;
		this.alpha = 1;
		this.setScale(0);
		this.tweens.add({
			targets:this,
			scale:1,
			duration:500,
			ease:'Back.easeOut',
		});
		if (stay){
			return;
        }
		this.tweens.add({
			targets:this,
			alpha:0,
			duration:500,
			ease:'Back.easeOut',
			delay:400,
		})
    }
}